<template>
  <div v-if="loading">
    <b-spinner class="mt-4"></b-spinner>
  </div>
  <div class="home" v-else-if="user">
    <b-alert
      variant="danger"
      :show="showVerificationAlert"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span id="ver__al"
        >Non hai ancora verificato il tuo indirizzo email

        <b-icon icon="info-circle" variant="info"></b-icon>
      </span>
      <b-tooltip target="ver__al" class="bg-info"
        >Per potere utilizzare la piattaforma è necessario verificare
        l'indirizzo email</b-tooltip
      >
    </b-alert>
    <b-alert
      variant="danger"
      :show="showAnagraficaAlert"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span id="angr__al"
        >Non hai ancora compilato la tua anagrafica

        <b-icon icon="info-circle" variant="info"></b-icon>
      </span>
      <b-tooltip target="angr__al" class="bg-info"
        >Per usufruire a pieno di tutti i servizi messi a disposizione da questa
        piattaforma è necessario compilare la propria scheda
        anagrafica</b-tooltip
      >
    </b-alert>
    <b-alert
      variant="warning"
      :show="showConsensesAlert"
      class="col-12 mr-0 ml-auto point"
    >
      <span id="cons__al" v-if="hideBackBtn === true"
        >Sottoscrizione in attesa</span
      >
      <span v-else id="cons__al"
        >Per proseguire devi leggere ed accettare termini e condizioni del
        servizio

        <b-icon icon="info-circle" variant="info"></b-icon>
      </span>
      <b-tooltip target="cons__al" class="bg-info"
        >Per usufruire a pieno di tutti i servizi messi a disposizione da questa
        piattaforma è necessario leggere ed accettare termini e condizioni del
        servizio</b-tooltip
      >
    </b-alert>
    <b-alert
      variant="warning"
      :show="showSubscriptionOptions"
      class="col-12 mr-0 ml-auto point"
    >
      <template v-if="user.type === 1 && !user.company.in_dismissione">
        <span v-if="!showPendingSubscription" id="cons__al"
          >Selezionare una sottoscrizione

          <b-icon icon="info-circle" variant="info"></b-icon>
        </span>
        <span v-else id="cons__al"
          >Sottoscrizione in attesa
          <b-icon icon="info-circle" variant="info"></b-icon>
        </span>
      </template>
      <span v-else id="cons__al"
        >Farmacia in dismissione

        <b-icon icon="info-warning" variant="danger"></b-icon>
      </span>
      <b-tooltip target="cons__al" class="bg-info"
        >Per usufruire a pieno di tutti i servizi messi a disposizione da questa
        piattaforma è necessario selezionare una sottoscrizione ed acquistare il
        servizio.</b-tooltip
      >
    </b-alert>
    <template v-if="showPendingSubscription && pendingSubs.abbonamento">
      <h5>In attesa del pagamento.</h5>
      <p class="my-4 text-center">
        Il contratto è stato sottoscritto e si attende il completamento del
        pagamento per l'attivazione dell'abbonamento.
      </p>
      <!-- 
      <a
        v-if="!user.company.dottorfarma"
        class="text-white text-decoration-none"
        :href="linkPagamenti + pendingSubs.abbonamento.wc_product_id"
        target="_blank"
      >
        <b-button
          variant="info info_gradient fw-bolder spacing_1 rounded-pill mb-0 mt-auto"
        >
          <span> Vai all'acquisto </span>
        </b-button>
      </a> -->

      <template v-if="!user.company.dottorfarma">
        <a
          v-if="pendingSubs.abbonamento.wc_product_id == 38"
          class="text-white font-decoration-none"
          :href="linkPagamenti + '4' + pendingSubs.abbonamento.wc_product_id"
          target="_blank"
        >
          <b-button
            variant="info info_gradient fw-bolder spacing_1 rounded-pill mb-0 mt-auto"
          >
            <span v-if="!gettingLink"> Vai all'acquisto </span>
            <b-spinner v-else></b-spinner>
          </b-button>
        </a>
        <a
          v-else
          class="text-white font-decoration-none"
          :href="linkPagamenti + pendingSubs.abbonamento.wc_product_id"
          target="_blank"
        >
          <b-button
            variant="info info_gradient fw-bolder spacing_1 rounded-pill mb-0 mt-auto"
          >
            <span v-if="!gettingLink"> Vai all'acquisto </span>
            <b-spinner v-else></b-spinner>
          </b-button>
        </a>
      </template>
      <b-button
        v-else
        variant="info info_gradient general_border fw-bolder"
        @click="getRedirectLink(pendingSubs.abbonamento.hashid)"
      >
        <span v-if="!gettingLink"> Vai all'acquisto </span>
        <b-spinner v-else></b-spinner
      ></b-button>
    </template>
    <template v-else-if="showSubscriptionOptions">
      <SottoscrizioniSelector
        @selected-subscription="handleSelectedSubscription($event)"
      ></SottoscrizioniSelector>
    </template>
    <template v-if="showConsensesAlert">
      <ConsenseComponentCompany
        v-if="user.type === 1"
        :subscription="selectedSubscription"
        v-on:consense-ok="handleConsense"
        @contract-ok="hideBackBtn = true"
      />
      <ConsenseComponent v-else v-on:consense-ok="handleConsense" />
      <b-row v-if="selectedSubscription" class="mt-4 pt-4">
        <b-button
          v-if="!hideBackBtn"
          variant="outline-primary fw-bolder"
          @click="backToSubs"
          >Torna indietro</b-button
        >
        <template v-if="showBuyLink">
          <template v-if="!user.company.dottorfarma">
            <b-button
              v-if="selectedSubscription.wc_product_id == 38"
              variant="info info_gradient rounded-pill mb-0 mt-auto"
            >
              <a
                class="text-white fw-bolder spacing_1 text-decoration-none"
                :href="linkPagamenti + '4' + selectedSubscription.wc_product_id"
                target="_blank"
              >
                <span> Vai all'acquisto </span>
              </a>
            </b-button>
            <b-button
              v-else
              variant="info info_gradient rounded-pill mb-0 mt-auto"
            >
              <a
                class="text-white fw-bolder spacing_1 text-decoration-none"
                :href="linkPagamenti + selectedSubscription.wc_product_id"
                target="_blank"
              >
                <span> Vai all'acquisto </span>
              </a>
            </b-button>
          </template>
          <b-button
            v-else
            variant="info info_gradient general_border fw-bolder"
            @click="getRedirectLink(selectedSubscription.hashid)"
          >
            <span v-if="!gettingLink"> Vai all'acquisto </span>
            <b-spinner v-else></b-spinner
          ></b-button>
        </template>
      </b-row>
    </template>
  </div>
  <h2 v-else class="text-primary">"-"</h2>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ConsenseComponent from "@/components/home/ConsenseComponent.vue";
import ConsenseComponentCompany from "@/components/home/ConsenseComponentCompany.vue";
import SottoscrizioniSelector from "@/components/home/SottoscrizioniSelector.vue";
import { userService, companyService } from "@/_services/";
export default {
  name: "Home",
  components: {
    ConsenseComponent,
    ConsenseComponentCompany,
    SottoscrizioniSelector,
  },
  computed: {
    linkPagamenti: function () {
      // var baseUrl = "https://pagamenti.federfarma.it";
      var baseUrl = this.pagamenti;
      if (this.user && this.user.company) {
        baseUrl = baseUrl + "?df_token=" + this.user.company.hashid;
        baseUrl += "&acquisto_corrente=";
      }
      return baseUrl;
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      showVerificationAlert: false,
      showAnagraficaAlert: false,
      showConsensesAlert: false,
      utente: null,
      showOtpVerificationAlert: true,
      loading: true,
      showSubscriptionOptions: false,
      selectedSubscription: null,
      hideBackBtn: false,
      showBuyLink: false,
      showPendingSubscription: false,
      pendingSubs: null,
      pagamenti: process.env.VUE_APP_PAGAMENTI_FEDERFARMA_URL,
      gettingLink: false,
    };
  },
  created() {
    this.verifyDottorfarmaSubscription();
    // this.checks();
  },
  methods: {
    ...mapActions("utente", ["unMe", "me"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    checks() {
      if (!this.user) {
        return this.unMe();
      }
      const self = this;
      userService
        .validateEmail(this.$route.query.access_token)
        .then(function (res) {
          const utente = res.data.data;
          //verifica contratto farmacia
          if (!utente.type === 1 && !utente.company.contract) {
            self.showConsensesAlert = true;
            return;
          }
          //verifica validazione numero
          if (!utente.number_verified_at) {
            self.showOtpVerificationAlert = true;
            self.$router.push({
              name: "verifica numero",
            });
            return;
          }
          // verifica validazione email
          if (utente.email && !utente.email_verified_at) {
            self.showVerificationAlert = true;
            self.$router.push({
              name: "EmailVerification",
              params: { status: "invalid" },
            });
            return;
          }
          //verifica compilazione anagrafica
          if (!utente.anagrafica && utente.type !== 2) {
            self.showAnagraficaAlert = true;
            self.loading = false;
            return;
          }
          //verifica accettazione informative
          if (!utente.all_consenses) {
            self.showConsensesAlert = true;
            self.loading = false;
            return;
          }
          //verifica validità password
          var editPasswordRoute = "Impostazioni";
          if (utente.i_am === "operatore" || utente.i_am === "admin") {
            editPasswordRoute = "Profilo";
          }
          if (utente.temporary_password) {
            self.$router.push({
              name: editPasswordRoute,
              query: { scope: "temporary" },
            });
            return;
          }
          //verifica validità password
          if (utente.expired_password == 1) {
            self.$router.push({
              name: editPasswordRoute,
              query: { scope: "expired" },
            });
            return;
          }
          self.redirectToOwnHome();
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    redirectToOwnHome() {
      if (this.$route.name === "Home") {
        this.$router.push("/" + this.user.i_am + "/home");
      }
    },
    handleConsense() {
      this.showConsensesAlert = false;
      if (this.selectedSubscription && this.selectedSubscription.prezzo > 0) {
        this.showBuyLink;
      } else {
        this.checks();
        // this.redirectToOwnHome();
      }
    },
    handleSelectedSubscription(subscription) {
      this.selectedSubscription = subscription;
      if (subscription !== null) {
        this.showConsensesAlert = true;
        this.showSubscriptionOptions = false;
      }
    },
    backToSubs() {
      this.selectedSubscription = null;
      this.showConsensesAlert = false;
      this.showSubscriptionOptions = true;
    },
    verifyDottorfarmaSubscription() {
      if (this.user.type !== 1) {
        return this.checks();
      }
      const self = this;
      companyService
        .verifyDottorfarmaSubscription()
        .then(function (res) {
          self.loading = false;
          self.showSubscriptionOptions =
            res.data.data.active_subscription === null;
          self.showPendingSubscription =
            res.data.data.pending_subscription !== null;
          self.pendingSubs = res.data.data.pending_subscription;
          if (!self.showSubscriptionOptions && !self.showPendingSubscription) {
            self.checks();
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getRedirectLink(productHashid) {
      this.gettingLink = true;
      const self = this;
      companyService
        .getFederfarmaPaymentLink(productHashid)
        .then(function (resp) {
          if (resp.data.data.redirect) {
            // window.location.href = resp.data.data.redirect;
            let link = document.createElement("a");
            link.href = resp.data.data.redirect;
            link.target = "_blank";
            link.click();
          }
          self.gettingLink = false;
        })
        .catch(function (error) {
          self.gettingLink = false;
          self.errorAlert(
            "Non è stato possibile procedere alla pagina di pagamento. Richiedere assistenza."
          );
        });
    },
  },
};
</script>
