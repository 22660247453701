import config from "../_config/config"
import { authHeader, postHeader } from "../_helpers"
import axios from "axios"

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest"
axios.defaults.headers.put["Content-Type"] = "application/json"
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest"

export const supportService = {
  searchCompany,
  updateRelationshipWithCompany,
  getCompany,
  getHealthServiceAllegati,
  addHealthServiceAllegato,
  removeHealthServiceAllegato,
  getActivities,
  storeActivity,
  updateActivity,
  deleteActivity,
  getSurveys,
  newQuestionario,
  getSurveyById,
  getSuspendedSurveyById,
  getFilledSurveyById,
  getFilledSurveyByIdWithInfo,
  storeFilledSurvey,
  getFilledForSurvey,
  surveyReport,
  companyUpdateSurveyActive,
  downloadFilledSurvey,
  attachSurveyToHealthService,
  getFreshHealthService,
  reportActivityPdf,
  getAllegato,
  editQuestionario,
  surveyXlsReport,
  reportActivityXls,
  getActivityReportForCompanies,
  getIrstTherapies,
  sendNoVarNotification,
  deleteIrstFarmaco,
  deleteIrstAltro,
  removeRelationshipWithCompany,
  arruolaPaziente,
  storeAlerts,
  getUserAlerts,
  updateUserAlerts,
  deleteUserAlerts,
  getUserTherapyAlerts,
  updateUserTherapyAlerts,
  copyUserTherapyAlerts,
  getAdherenceIndex,
  getScaricabili,
  getTotalPatients,
  getCompanyHealthServices,
  downloadFilledSurveyPatientVersion,
  getPillole,
  getPatientsList,
  downloadFDSPatientReport,
  updateAssistitoAnagrafica,
  getActivitiesStatistics,
  getActivitiesStatisticsReport,
  getUserFilledSurveys,
  checkIfFilledSurveyAlreadyExists,
  calcSurveyScore,
  checkIfSurveyHasReachedLimit,
  checkForPastFilledSurveyFromAnotherCompany,
  editFilledSurvey
}

function searchCompany(filters) {
  var sett = { headers: authHeader(), params: filters }
  return axios
    .get("companies", sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function updateRelationshipWithCompany(data) {
  var sett = { headers: authHeader() }
  return axios
    .post("users/company/relationship", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function removeRelationshipWithCompany(data) {
  var sett = { headers: authHeader() }
  return axios
    .post("users/company/relationship/remove", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getCompany(hash) {
  const sett = { headers: authHeader() }
  return axios
    .get("companies/" + hash, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getHealthServiceAllegati(hash) {
  const sett = { headers: authHeader() }
  var entpoint = "healthservices/allegati/all/" + hash
  return axios
    .get(entpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function addHealthServiceAllegato(data) {
  const sett = { headers: authHeader() }
  var entpoint = "healthservices/allegati/add/" + data.hash
  return axios
    .post(entpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function removeHealthServiceAllegato(data) {
  const sett = { headers: authHeader() }
  var entpoint = "healthservices/allegati/remove/" + data.hash
  return axios
    .post(entpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getActivities(filters) {
  const sett = { headers: authHeader(), params: filters }
  return axios
    .get("activities", sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function storeActivity(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("activities", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function updateActivity(data) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/" + data.hash
  return axios
    .put(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getSurveys(filters) {
  const sett = { headers: authHeader(), params: filters }
  return axios
    .get("activities/own/surveys", sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function newQuestionario(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("activities/surveys/new", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getSurveyById(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/surveys/get/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getFilledSurveyByIdWithInfo(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/surveys/filled/get/" + hash + "/complete"
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getSuspendedSurveyById(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/surveys/suspended/get/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getFilledSurveyById(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/surveys/filled/get/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function storeFilledSurvey(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("activities/surveys/filled/new", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function editQuestionario(data) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/surveys/edit/" + data.hash
  return axios
    .put(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getFilledForSurvey(data) {
  const sett = { headers: authHeader(), params: data.filters }
  var endpoint = "activities/surveys/filled/all/" + data.hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function surveyReport(hash) {
  const sett = { headers: authHeader(), responseType: "blob" }
  var endpoint = "activities/surveys/filled/pdf/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function surveyXlsReport(hash) {
  const sett = { headers: authHeader(), responseType: "blob" }
  var endpoint = "activities/surveys/filled/xls/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function companyUpdateSurveyActive(data) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/surveys/active/update/" + data.hash
  return axios
    .put(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function downloadFilledSurvey(hash) {
  const sett = { headers: authHeader(), responseType: "blob" }
  var endpoint = "activities/survey/filled/pdf/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function downloadFilledSurveyPatientVersion(hash) {
  const sett = { headers: authHeader(), responseType: "blob" }
  var endpoint = "activities/survey/filled/patient/pdf/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function attachSurveyToHealthService(data) {
  const sett = { headers: authHeader() }
  var endpoint = "healthservices/survey/attach/" + data.hash
  return axios
    .post(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getFreshHealthService(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "healthservices/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function reportActivityPdf(hash) {
  const sett = { headers: authHeader(), responseType: "blob" }
  var endpoint = "activities/report/filled/pdf/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function reportActivityXls(hash) {
  const sett = { headers: authHeader(), responseType: "blob" }
  var endpoint = "activities/report/filled/xls/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getAllegato(hash) {
  const sett = { headers: authHeader(), responseType: "arraybuffer" }
  var endpoint = "resources/private/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getActivityReportForCompanies(data) {
  const sett = { headers: authHeader(), responseType: "blob" }
  var endpoint = "activities/report/filled/xls/" + data.hash
  return axios
    .post(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getIrstTherapies(filters) {
  const sett = { headers: authHeader(), params: filters }
  return axios
    .get("activities/irst/therapies", sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function sendNoVarNotification(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("activities/irst/novar", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function deleteIrstFarmaco(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/irst/farmaco/delete/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function deleteIrstAltro(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/irst/altro/delete/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function arruolaPaziente(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("companies/paziente/register", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function storeAlerts(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("alerts", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getUserAlerts(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "alerts/user/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getUserTherapyAlerts(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "alerts/user/therapy/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function updateUserTherapyAlerts(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("alerts/users/therapy/update", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function updateUserAlerts(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("alerts/status/update", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function deleteUserAlerts(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("alerts/users/delete", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function copyUserTherapyAlerts(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("alerts/users/therapy/copy", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getAdherenceIndex(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "users/adherence/index/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getScaricabili() {
  const sett = { headers: authHeader() }
  var endpoint = "resources/public/scaricabili"
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getPillole() {
  const sett = { headers: authHeader() }
  var endpoint = "resources/public/pillole"
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getTotalPatients() {
  const sett = { headers: authHeader() }
  var endpoint = "companies/own/patients"
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function deleteActivity(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "activities/" + hash
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getCompanyHealthServices(hash) {
  const sett = { headers: authHeader() }
  var endpoint = "healthservices/company/all/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getPatientsList(filters) {
  const sett = { headers: authHeader(), params: filters }
  var endpoint = "companies/own/patients/list"
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function downloadFDSPatientReport(hash, filters) {
  const sett = { headers: authHeader(), params: filters, responseType: "blob" }
  var endpoint = "activities/report/filled/pdf/survey/view/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function updateAssistitoAnagrafica(hash, data) {
  const sett = { headers: authHeader() }
  const endpoint = "companies/own/assistito/anagrafica/" + hash
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getActivitiesStatistics(filters) {
  const sett = { headers: authHeader(), params: filters }
  return axios
    .get("companies/own/activities/statistics", sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getActivitiesStatisticsReport(filters) {
  const sett = { headers: authHeader(), params: filters, responseType: "blob" }
  return axios
    .get("companies/own/activities/statistics/report", sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function getUserFilledSurveys(hash, filters) {
  const sett = { headers: authHeader(), params: filters }
  var endpoint = "activities/surveys/filled/user/" + hash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function checkIfFilledSurveyAlreadyExists(userHash, surveyHash) {
  const sett = { headers: authHeader(), params: { user_id: userHash } }
  var endpoint = "activities/surveys/filled/check/" + surveyHash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function calcSurveyScore(data) {
  const sett = { headers: authHeader() }
  return axios
    .post("activities/surveys/filled/scored/calc", data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function checkIfSurveyHasReachedLimit(surveyHash, userHash) {
  const sett = { headers: authHeader(), params: { user_id: userHash } }
  var endpoint = "activities/surveys/" + surveyHash + "/limit/check/"
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function checkForPastFilledSurveyFromAnotherCompany(surveyHash, userHash) {
  const sett = { headers: authHeader(), params: { user_id: userHash } }
  var endpoint = "activities/surveys/filled/check/past/" + surveyHash
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}

function editFilledSurvey(hash, data) {
  const sett = { headers: authHeader() }
  const endpoint = "activities/surveys/filled/" + hash
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch((err) => {
      var error = err.response
      return Promise.reject(error)
    })
}
