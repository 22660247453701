<template>
  <div>
    <div class="d-flex justify-content-between">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{
          name: 'dettagli attività',
          params: { activity: $route.params.activity }
        }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Indietro
      </router-link>
    </div>
    <!-- <div class="py-3" v-if="template"> -->
    <div class="py-3">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Questionari
        <span v-if="activity">{{ activity.name }}</span> somministrati
        <span v-if="company">dalla farmacia {{ company.denominazione }}</span>
      </h6>
      <p class="text-justify text-medium-grey fw-bolder" v-if="company">
        {{ company.denominazione }} -
        <span class="text-capitalize"
          >{{ company.regione }}, {{ company.provincia }}</span
        >
        - codice tracciabilità:<span class="text-capitalize">{{
          company.codice_tracciabilita
        }}</span>
      </p>

      <b-row
        cols="2"
        cols-md="3"
        cols-lg="4"
        align-v="end"
        class="justify-items-start bg-light py-2"
      >
        <b-form-group
          label="Questionari/Servizi"
          label-for="questionario"
          class="text-left ml-0 mr-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6 px-2"
            name="questionario"
            v-model="filterSetted.survey"
            required
            @change="fetchData"
          >
            <b-form-select-option
              v-for="(surv, ind) in surveyOptions"
              :key="ind"
              :value="surv.name"
              >{{ surv.name }}</b-form-select-option
            >
            <b-form-select-option :value="null">Tutti</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Inserimenti dal"
          label-for="addfrom"
          class="text-left ml-0 mr-auto col mb-1"
        >
          <b-form-input
            :max="todayLimit"
            class="my-1 shadow_6 px-2"
            name="addfrom"
            type="date"
            v-model="filterSetted.addfrom"
            @change="fetchData"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Inserimenti al"
          label-for="addto"
          class="text-left ml-0 mr-auto col mb-1"
        >
          <b-form-input
            :max="todayLimit"
            class="my-1 shadow_6 px-2"
            name="addto"
            type="date"
            v-model="filterSetted.addto"
            @change="fetchData"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Livello criticità"
          label-for="threshold_level"
          class="text-left ml-0 mr-auto col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6 px-2"
            name="threshold_level"
            v-model="filterSetted.threshold_level"
            required
            @change="fetchData"
          >
            <b-form-select-option
              v-for="(level, ind) in thresholdOptions"
              :key="level.key"
              :value="level.value"
              >{{ level.label }}</b-form-select-option
            >
            <b-form-select-option :value="null">Tutti</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <!-- <b-col></b-col> -->
        <b-col class="ml-0 text-left pb-4 pt-3">
          <b-form-group
            label="Codice fiscale"
            label-for="cf"
            class="text-left ml-0 mb-1"
          >
            <b-form-input
              class="shadow_6 px-2"
              name="cf"
              type="text"
              v-model="filterSetted.cf"
              placeholder="Codice Fiscale"
              @change="fetchData"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-items-center pt-4 pb-4">
          <b-dropdown
            id="dropdown-downloader"
            text="Download selezione"
            class="d-flex align-items-center"
            variant="primary primary_gradient text-white fw-bolder spacing_1"
          >
            <template v-if="user.permessi.includes('vedere dati cittadini')">
              <b-dropdown-item @click="getReport"
                >PDF in chiaro</b-dropdown-item
              >
              <b-dropdown-item @click="getReportXsl"
                >excel in chiaro</b-dropdown-item
              >
            </template>
            <b-dropdown-item @click="getReportPseudo"
              >PDF pseudoanonimo</b-dropdown-item
            >
            <b-dropdown-item @click="getReportPseudoXsl"
              >excel pseudoanonimo</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
        <b-col class="ml-0 text-left pt-4 pb-4">
          <b-button
            variant="warning"
            class="fw-bolder text-white"
            @click="resetFilters"
            >Reset
          </b-button>
        </b-col>
      </b-row>

      <!-- <div class="d-flex mb-3">
        <b-button variant="primary primary_gradient text-white fw-bolder spacing_1" class="m-1" @click="getReport"
          :disabled="total == 0">Scarica tutto in formato pdf</b-button>
        <b-button variant="primary primary_gradient text-white fw-bolder spacing_1" class="m-1" @click="getReportXsl"
          :disabled="total == 0">Scarica tutto in formato excel</b-button>
      </div> -->
      <div class="d-flex align-items-center">
        <b-form-group size="sm" class="fw-bolder text-left mr-3">
          <b-form-select
            class="align-items-center align-content-center shadow_6 border border-info text-info"
            name="vedi"
            v-model="filterSetted.take"
            :options="takeOptions"
          >
          </b-form-select>
        </b-form-group>
        <span class="text-info fw-bolder mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info fw-bolder"> {{ total }}</span>
      </div>
      <div class="mt-2">
        <b-table
          borderless
          hover
          responsive
          tbody-tr-class="text-black border-bottom border-medium-grey"
          small
          thead-class=" border-medium-grey text-left"
          table-variant="white text-info  text-left row__font"
          :fields="fields"
          :items="surveys"
          :small="$root.isMobile"
        >
          <template #cell(company_denominazione)="row">
            <span class="text-left text-capitalize fw-bolder">{{
              row.item.company_denominazione
            }}</span>
          </template>
          <template #cell(created_at)="row">
            <span class="text-left text-capitalize fw-bolder">{{
              row.item.created_at | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(user_mail)="row">
            <template v-if="user.permessi.includes('vedere dati cittadini')">
              <span
                class="text-left fw-bolder text-uppercase"
                v-if="row.item.user_cf"
              >
                {{ row.item.user_cf }}
              </span>
              <span class="text-left fw-bolder" v-else>{{
                row.item.user_mail ? row.item.user_mail : "---"
              }}</span>
            </template>
            <span v-else> --- </span>
          </template>
          <template #cell(azioni)="row">
            <template v-if="user.permessi.includes('vedere dati cittadini')">
              <b-button
                size="sm"
                variant="info info_gradient fw-bolder rounded-pill px-3"
                @click="showThis(row)"
                >Vedi
              </b-button>
              <b-button
                v-if="user.permessi.includes('eliminare questionari')"
                size="sm"
                variant="warning fw-bolder rounded-pill px-3 text-white"
                @click="toggleEditability(row.item)"
                >{{
                  row.item.editable
                    ? "Disabilita alla modifica"
                    : "Abilita alla modifica"
                }}
              </b-button>
              <b-button
                size="sm"
                variant="primary primary_gradient text-white fw-bolder spacing_1 rounded-pill px-3"
                @click="downloadOne(row)"
                >Scarica</b-button
              >
              <b-button
                v-if="user.permessi.includes('eliminare questionari')"
                size="sm"
                variant="danger fw-bolder rounded-pill px-3"
                @click="
                  selectedItem = row.item
                  $bvModal.show('del-survey-report')
                "
                >Elimina
              </b-button>
            </template>

            <!-- <b-button v-if="row.item.user_mail" size="sm"
              variant="primary primary_gradient text-white fw-bolder spacing_1 rounded-pill px-3"
              @click="downloadPatientVersion(row)">
              <b-icon icon="download"></b-icon>
              pdf per il paziente
            </b-button> -->
          </template>
        </b-table>
        <p v-if="surveys && surveys.length < 1">Nessun questionario qui</p>
        <b-pagination
          v-model="filterSetted.page"
          pills
          :total-rows="total"
          :per-page="perPage"
          align="center"
          :size="$root.isMobile ? 'sm' : 'lg'"
        ></b-pagination>
        <b-modal
          ref="show-filleditem"
          hide-footer
          title="Questionario"
          size="xl"
          scrollable
        >
          <FilledSurveyShower
            v-if="selectedItem"
            :response="selectedItem.response"
            :score="selectedItem.score"
            :identificativo_deblistering="
              selectedItem.identificativo_deblistering
            "
          />
        </b-modal>
      </div>
    </div>
    <b-modal
      id="del-survey-report"
      size="lg"
      centered
      title="Conferma eliminazione"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="secondary px-3 rounded-pill font-weight-bolder"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger px-3 font-weight-bolder rounded-pill"
      footer-class="border-0"
      @ok="deleteSurvey"
      @cancel="$bvModal.hide('del-survey-report')"
    >
      Confermi di voler eliminare il questionario?
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { supportService, adminService } from "@/_services"
import { mapState, mapActions } from "vuex"
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue"
import { format } from "path"
export default {
  name: "ReportCompilatiAbilitata",
  components: { FilledSurveyShower },
  data() {
    const today = moment().format("YYYY-MM-DD")
    return {
      filterSetted: {
        take: 10,
        page: 1,
        company: this.$route.params.company,
        survey: null,
        addfrom: null,
        addto: null,
        threshold_level: null
      },
      surveys: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 0,
      fields: [
        // { key: "company_denominazione", label: "Azienda" },
        { key: "survey_name", label: "Nome questionario" },
        { key: "created_at", label: "Data compilazione" },
        { key: "user_mail", label: "Paziente" },
        // { key: "active", label: "Stato" },
        { key: "azioni", label: "" }
      ],
      takeOptions: [10, 25, 50, 100],
      template: null,
      selectedItem: null,
      company: null,
      surveyOptions: null,
      todayLimit: today,
      activity: null,
      thresholdOptions: [
        { key: "assente", value: 1, label: "assente/bassa" },
        { key: "presente", value: 3, label: "media/alta" }
      ]
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        // //console.log("changed prev", prev.status, post);
        // //console.log("changed post", post.status);
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      var dat = {
        hash: this.$route.params.activity,
        filters: this.filterSetted
      }
      adminService
        .getFilledSurveysForActivity(dat)
        .then(function (res) {
          // console.log(res);
          self.surveys = res.data.data.data
          self.filterSetted.page = res.data.data.current_page
          self.total = res.data.data.total
          self.fromPage = res.data.data.from
          self.toPage = res.data.data.to
          self.perPage = res.data.data.per_page
          // self.template = res.data.data.survey;
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    deleteSurvey() {
      var self = this
      adminService
        .deleteFilledSurvey(this.selectedItem.hashid)
        .then(function (response) {
          self.successAlert("Questionario eliminato con successo")
          self.fetchData()
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile eliminare il questionario")
        })
    },
    showThis(row) {
      this.selectedItem = row.item
      this.$refs["show-filleditem"].show()
    },
    resetFilters() {
      this.filterSetted = {
        take: 10,
        page: 1,
        company: this.$route.params.company,
        survey: null,
        addfrom: null,
        addto: null
      }
    },
    getReport() {
      var today = moment().format("DD/MM/YYYY")
      var self = this
      var filters = {
        company: this.filterSetted.company,
        survey: this.filterSetted.survey,
        addfrom: this.filterSetted.addfrom,
        addto: this.filterSetted.addto
      }
      adminService
        .reportActivityPdf(this.$route.params.activity, filters)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "report_" + today + ".pdf"
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    getReportXsl() {
      var today = moment().format("DD/MM/YYYY")
      var self = this
      var filters = {
        company: this.filterSetted.company,
        survey: this.filterSetted.survey,
        addfrom: this.filterSetted.addfrom,
        addto: this.filterSetted.addto
      }
      adminService
        .reportActivityXls(this.$route.params.activity, filters)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "report_" + today + ".xlsx"
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    getReportPseudoXsl() {
      var today = moment().format("DD/MM/YYYY")
      var self = this
      var filters = {
        company: this.filterSetted.company,
        survey: this.filterSetted.survey,
        addfrom: this.filterSetted.addfrom,
        addto: this.filterSetted.addto
      }
      adminService
        .reportActivityPseudonymXls(this.$route.params.activity, filters)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "report_" + today + ".xlsx"
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    getReportPseudo() {
      var today = moment().format("DD/MM/YYYY")
      var self = this
      var filters = {
        company: this.filterSetted.company,
        survey: this.filterSetted.survey,
        addfrom: this.filterSetted.addfrom,
        addto: this.filterSetted.addto
      }
      adminService
        .reportActivityPseudonymPdf(this.$route.params.activity, filters)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "report_" + today + ".pdf"
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    downloadOne(row) {
      // console.log(row)
      var self = this
      var selected = row
      var fName = selected.item.survey_name
      if (selected.item.user_cf) {
        fName += "_" + selected.item.user_cf
      }
      fName += ".pdf"
      supportService
        .downloadFilledSurvey(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    getCompany() {
      var self = this
      supportService
        .getCompany(this.$route.params.company)
        .then(function (response) {
          self.company = response.data.data
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    fetchSurveys(aName) {
      var self = this
      var filters = {
        activity: aName
      }
      supportService
        .getSurveys(filters)
        .then(function (response) {
          self.surveyOptions = response.data.data.data
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile recuperare le i questionari dell'attività"
          )
          console.log(err)
        })
    },
    fetchActivity() {
      var self = this
      adminService
        .getActivityById(this.$route.params.activity)
        .then(function (res) {
          self.activity = res.data.data
          self.fetchSurveys(res.data.data.name)
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le attività")
          console.log(err)
        })
      //
    },
    toggleEditability(item) {
      var self = this
      adminService
        .toggleFilledSurveyEditability(item.hashid)
        .then(function (response) {
          self.successAlert(
            `La modifica del questionario è ora ${
              item.editable ? "disabilitata" : "abilitata"
            }`
          )
          self.fetchData()
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile modificare il questionario")
        })
    }
  },
  created() {
    if (this.$route.params.activity && this.$route.params.company) {
      this.fetchData()
      this.getCompany()
      this.fetchActivity()
    }
  }
}
</script>

<style>
#dropdown-downloader__BV_toggle_ {
  max-width: 230px !important;
  font-size: 16px;
  padding: 10px 1.2rem;
}
</style>
