<template>
  <div class="register pb-4">
    <p class="mb-1 text-dark-grey text-left">
      Arruola un paziente non presente in piattaforma. Età minima richiesta per
      la registrazione: 14 anni.
    </p>
    <div>
      <b-card no-body class="border-0">
        <b-form @submit.prevent="OnSubmit" class="pt-4">
          <!-- <div class="mx-auto"> -->
          <b-row>
            <b-form-group
              label="Titolo"
              label-for="titolo"
              class="text-left fs-6 text-font-grey mr-auto col-12 col-md-6"
            >
              <b-form-select
                class="my-3 shadow_6 text-capitalize"
                :options="titleOptions"
                v-model="anagrafica.title"
              >
                <b-form-select-option disabled :value="null"
                  >Titolo</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-row>
          <div class="col-12 d-flex flex-wrap px-0">
            <b-form-group
              label="Nome*"
              label-for="name"
              class="text-left fs-6 text-font-grey mx-auto"
              :class="$root.isMobile ? '' : 'col'"
            >
              <b-form-input
                class="my-3 shadow_6 text-capitalize"
                name="name"
                type="text"
                placeholder="nome..."
                v-model="anagrafica.name"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Cognome*"
              label-for="surname"
              class="text-left fs-6 text-font-grey mx-auto"
              :class="$root.isMobile ? '' : 'col'"
            >
              <b-form-input
                class="my-3 shadow_6 text-capitalize"
                name="surname"
                type="text"
                placeholder="cognome..."
                v-model="anagrafica.surname"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 d-flex flex-wrap px-0">
            <b-form-group
              label="Codice fiscale*"
              label-for="cf"
              class="text-left fs-6 text-font-grey mx-auto"
              :class="$root.isMobile ? '' : 'col'"
            >
              <b-form-input
                class="my-3 shadow_6 text-uppercase"
                name="cf"
                type="text"
                placeholder="codice fiscale..."
                maxlength="16"
                minlength="16"
                v-model="anagrafica.cf"
                :state="anagrafica.cf == null ? null : validCf"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="
                form.nomail
                  ? 'Numero Tessera Sanitaria*'
                  : 'Numero Tessera Sanitaria'
              "
              label-for="ts_number"
              class="text-left fs-6 text-font-grey mx-auto"
              :class="$root.isMobile ? '' : 'col'"
            >
              <b-form-input
                class="my-3 shadow_6"
                name="ts_number"
                type="text"
                placeholder="numero tessera..."
                v-model="anagrafica.ts_number"
                maxlength="20"
                minlength="20"
                :required="form.nomail"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 d-flex flex-wrap px-0">
            <b-form-group
              label="Data di nascita*"
              label-for="birthday"
              class="text-left fs-6 text-font-grey mx-auto"
              :class="$root.isMobile ? '' : 'col'"
            >
              <b-form-input
                class="my-3 shadow_6"
                name="birthday"
                type="date"
                v-model="anagrafica.birthday"
                required
              >
              </b-form-input>
            </b-form-group>

            <b-form-group
              label="Genere"
              label-for="gender"
              class="text-left fs-6 text-font-grey mx-auto"
              :class="$root.isMobile ? '' : 'col'"
            >
              <b-form-radio-group
                v-model="anagrafica.gender"
                name="gender-options"
                class="d-flex"
              >
                <b-form-radio value="maschile">uomo</b-form-radio>
                <b-form-radio value="femminile">donna</b-form-radio>
                <b-form-radio :value="null">non indicato</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <!-- </div> -->
          <div class="col-12 d-flex flex-wrap px-0">
            <b-form-group
              label="Numero di telefono*"
              label-for="tel_number"
              class="text-left fs-6 text-font-grey mx-auto"
              :class="$root.isMobile ? '' : 'col'"
            >
              <b-input-group class="">
                <b-input-group-prepend>
                  <h6 class="my-4 pt-2 pr-1 text-prim-grad-1">+39</h6>
                </b-input-group-prepend>
                <b-form-input
                  class="my-3 shadow_6"
                  name="tel_number"
                  type="text"
                  placeholder="telefono..."
                  v-model="anagrafica.tel_number"
                  minlength="9"
                  required
                  :state="anagrafica.tel_number !== null ? validNumber : null"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Numero di telefono domicilio"
              label-for="home_number"
              class="text-left fs-6 text-font-grey mx-auto"
              :class="$root.isMobile ? '' : 'col'"
            >
              <b-input-group class="">
                <b-form-input
                  class="my-3 shadow_6"
                  name="home_number"
                  type="text"
                  placeholder="telefono domicilio..."
                  v-model="anagrafica.home_number"
                  minlength="9"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="d-flex flex-wrap px-3">
            <b-form-group
              label="Nazione di nascita*"
              label-for="nazione_nascita"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-sm-6"
            >
              <b-form-select
                class="my-3 shadow_6 text-capitalize"
                :options="nazioniOptions"
                name="nazione_nascita"
                v-model="anagrafica.nazione_nascita"
                required
              >
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Regione di residenza*"
              label-for="regione"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-sm-6"
            >
              <b-form-select
                class="my-3 shadow_6 text-capitalize"
                :options="regioni"
                name="regione"
                v-model="selected.regione"
                @change="getProvince(selected)"
                required
              >
                <b-form-select-option :value="null"
                  >Seleziona Regione</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Provincia di residenza*"
              label-for="provincia"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-sm-6"
            >
              <b-form-select
                class="my-3 shadow_6 text-capitalize"
                :options="province"
                name="provincia"
                v-model="selected.provincia"
                @change="getComuni(selected)"
                required
              >
                <b-form-select-option :value="null"
                  >Seleziona Provincia</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Comune di residenza*"
              label-for="comune"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-sm-6"
            >
              <b-form-select
                class="my-3 shadow_6 text-capitalize"
                name="comune"
                v-model="selected.comune"
                required
              >
                <b-form-select-option
                  v-for="(com, ind) in comuni"
                  :key="ind"
                  :value="com"
                  @change="setDomicilioData"
                  :selected="findComune(com)"
                  >{{ com.comune }}</b-form-select-option
                >
                <b-form-select-option :value="null"
                  >Seleziona Comune</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Cap"
              label-for="cap"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-sm-6"
            >
              <b-form-input
                class="my-3 shadow_6"
                name="cap"
                type="text"
                placeholder="cap..."
                v-model="anagrafica.cap"
                minlength="5"
                maxlength="5"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Indirizzo e numero civico"
              label-for="address"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-sm-6"
            >
              <b-form-input
                class="my-3 shadow_6"
                name="address"
                type="text"
                placeholder="indirizzo..."
                v-model="anagrafica.address"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 d-flex flex-wrap px-3"></div>
          <div>
            <template v-if="form.nomail">
              <b-row class="text-left">
                <b-col class="col-12 col-sm-6 mb-4">
                  <b-form-group
                    label-for="nomail"
                    class="text-left text-font-grey mx-auto"
                  >
                    <b-form-checkbox
                      name="nomail"
                      class="mt-3"
                      v-model="form.nomail"
                      :value="true"
                      :unchecked-value="false"
                    >
                      Il paziente non possiede un indirizzo email
                    </b-form-checkbox>
                  </b-form-group>
                  <p class="text-secondary text-justify fw-bolder mb-0">
                    Se il paziente non dispone di una casella email è necessario
                    inserire il numero della tessera sanitaria e gli estremi di
                    un documento di identità.
                  </p>
                </b-col>
                <b-col class="col-12 col-lg-6 mb-4">
                  <b-form-group
                    label="Tipo documento*"
                    label-for="id_type"
                    class="text-left fs-6 text-font-grey mx-auto"
                  >
                    <b-input-group>
                      <b-form-select
                        class="my-3 shadow_6"
                        name="id_type"
                        type="text"
                        v-model="anagrafica.id_type"
                        required
                      >
                        <b-form-select-option :value="null" disabled
                          >seleziona tipo documento</b-form-select-option
                        >
                        <b-form-select-option :value="0"
                          >carta di identità</b-form-select-option
                        >
                        <b-form-select-option :value="1"
                          >patente</b-form-select-option
                        >
                        <b-form-select-option :value="2"
                          >passaporto</b-form-select-option
                        >
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-lg-6">
                  <b-form-group
                    label="Numero documento*"
                    label-for="id_number"
                    class="text-left fs-6 text-font-grey mx-auto"
                  >
                    <b-input-group>
                      <b-form-input
                        class="my-3 shadow_6"
                        name="id_number"
                        type="text"
                        placeholder="ID..."
                        v-model="anagrafica.id_number"
                        minlength="5"
                        required
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col class="col-12 col-lg-6 mb-4 general_border">
                  <b-form-group
                    label="Telefono di riserva"
                    label-for="tel_number_secondary"
                    class="text-left fs-6 text-font-grey mx-auto"
                  >
                    <b-input-group class="">
                      <b-input-group-prepend>
                        <h6 class="my-4 pt-2 pr-1 text-prim-grad-1">+39</h6>
                      </b-input-group-prepend>
                      <b-form-input
                        class="my-3 shadow_6"
                        name="tel_number_secondary"
                        type="text"
                        placeholder="telefono..."
                        v-model="anagrafica.tel_number_secondary"
                        minlength="9"
                        :state="
                          anagrafica.tel_number_secondary !== null
                            ? validSecondaryNumber
                            : null
                        "
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>

            <template v-else>
              <div class="col-12 d-flex flex-wrap px-3">
                <b-form-group
                  label="Email*"
                  label-for="email"
                  class="text-left fs-6 text-font-grey col-12"
                >
                  <b-form-input
                    v-model="form.email"
                    placeholder="email..."
                    class="my-3 shadow_6"
                    name="email"
                    :state="form.email == null ? null : validEmail"
                    :disabled="!anagrafica.name || !anagrafica.surname"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <b-form-group
                label-for="nomail"
                class="text-left text-font-grey mx-auto px-4"
              >
                <b-form-checkbox
                  name="nomail"
                  class="mt-3"
                  v-model="form.nomail"
                  :value="true"
                  :unchecked-value="false"
                >
                  Il paziente non possiede un indirizzo email
                </b-form-checkbox>
              </b-form-group>
            </template>
          </div>
          <p class="px-4 text-left text-medium-grey fs-6">
            *parametri obbligatori.
          </p>
          <h6 class="text-justify">
            Prima di procedere si prega di verificare che le informazioni
            inserite siano corrette, poiché dopo aver cliccato su "Arruola" non
            sarà più possibile modificarle. Dopo aver inoltrato la scheda di
            arruolamento il sistema invierà una email di verifica contenente
            delle credenziali di accesso temporanee all'indirizzo email
            indicato, se indicato; verrà inoltre richiesta la validazione del
            numero di telefono del paziente tramite invio SMS di un codice OTP.
          </h6>
          <b-col class="my-3 px-0">
            <p class="text-center fw-bolder">
              Per proseguire è necessario che il paziente
              <span
                target="_blank"
                class="point"
                @click="$bvModal.show('informativa_arruolamento')"
              >
                <u> legga ed accetti l'informativa</u></span
              >.
              <a
                href="/assets/informativa_profilo_sanitario_arruolamento.pdf"
                target="_blank"
                ><span class="point underlined text-prim-grad-1 fw-bolder"
                  >Stampa informativa</span
                ></a
              >
            </p>
            <b-form-checkbox
              class="text-font-light-grey"
              name="data_read_consense"
              :value="true"
              :unchecked-value="false"
              v-model="informRead"
              :state="informRead == true"
            >
              Ho letto e compreso
              <span
                target="_blank"
                class="point"
                @click="$bvModal.show('informativa_arruolamento')"
              >
                <u>l'informativa privacy</u></span
              >
            </b-form-checkbox>
            <b-form-checkbox
              class="text-font-light-grey"
              name="data_consense"
              :value="true"
              :unchecked-value="false"
              v-model="informAccepted"
              :state="informAccepted == true"
            >
              Acconsento al trattamento dei miei dati personali per la
              condivisione con il farmacista di dati relativi alla mia salute;
            </b-form-checkbox>
            <b-form-checkbox
              class="text-font-light-grey"
              name="data_consense_second"
              :value="true"
              :unchecked-value="false"
              v-model="informAccepted2"
              :state="informAccepted2 == true"
            >
              Acconsento al trattamento dei miei dati personali per la
              valutazione della mia aderenza terapeutica con finalità di
              sensibilizzazione;
            </b-form-checkbox>
          </b-col>
          <div class="col-12 text-right mt-3">
            <b-button
              type="button"
              variant="outline-secondary mx-2"
              @click="$emit('cancel-operation')"
              >Indietro
            </b-button>
            <b-button
              type="submit"
              variant="primary text-white primary_gradient mx-2"
              :disabled="!informRead || !informAccepted || !informAccepted2"
              >Arruola
              <b-icon icon="arrow-right"></b-icon>
            </b-button>
          </div>
        </b-form>
      </b-card>
    </div>
    <b-modal
      id="informativa_arruolamento"
      hide-footer
      centered
      size="xl"
      scrollable
    >
      <template v-if="user">
        <InformativaArruolamento
          :company="user.company"
        ></InformativaArruolamento>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { supportService, utilityService } from "@/_services";
import { mapState, mapActions } from "vuex";
import InformativaArruolamento from "@/components/pazienti/InformativaArruolamento.vue";
export default {
  name: "SchedaArruolamento",
  props: ["params"],
  components: { InformativaArruolamento },
  data() {
    return {
      form: {
        email: null,
        // password: null,
        // password_confirmation: null,
        // data_consense: false,
        // share_consense: false,
        // notification_consense: false,
        // charge_consense: false,
        nomail: false,
        type: 0,
        anagrafica: null,
      },
      informAccepted: false,
      informAccepted2: false,
      informRead: false,
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
        tel_number_secondary: null,
        id_number: null,
        id_type: null,
        home_number: null,
        title: null,
        nazione_nascita: null,
      },
      titleOptions: ["Sig.", "Sig.ra", "Dott.", "Dott.ssa"],
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      cfMonths: {
        A: "01",
        B: "02",
        C: "03",
        D: "04",
        E: "05",
        H: "06",
        L: "07",
        M: "08",
        P: "09",
        R: "10",
        S: "11",
        T: "12",
        a: "01",
        b: "02",
        c: "03",
        d: "04",
        e: "05",
        h: "06",
        l: "07",
        m: "08",
        p: "09",
        r: "10",
        s: "11",
        t: "12",
      },
      nazioni: [],
    };
  },
  watch: {
    selected: {
      handler(val) {
        // console.log(val);
        if (val.comune !== null) {
          this.anagrafica.comune = this.selected.comune.comune;
          this.anagrafica.provincia = this.selected.comune.provincia;
          this.anagrafica.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
    validEmail() {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.form.email.match(validRegex) ? true : false;
    },
    validCf() {
      var validRegex = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      if (this.anagrafica.cf.length === 16) {
        return this.anagrafica.cf.match(validRegex) ? this.fillOthers() : false;
      }
      return this.anagrafica.cf.match(validRegex) ? true : false;
    },
    validNumber() {
      if (this.anagrafica.tel_number) {
        const prefix = this.anagrafica.tel_number.slice(0, 2);
        const hasPrefix =
          prefix === "00" ||
          this.anagrafica.tel_number.indexOf("+") >= 0 ||
          this.anagrafica.tel_number.indexOf("0") === 0;
        return !isNaN(this.anagrafica.tel_number) && !hasPrefix;
      }
      return false;
    },
    validSecondaryNumber() {
      if (this.anagrafica.tel_number_secondary) {
        const prefix = this.anagrafica.tel_number_secondary.slice(0, 2);
        const hasPrefix =
          prefix === "00" ||
          this.anagrafica.tel_number_secondary.indexOf("+") >= 0 ||
          this.anagrafica.tel_number_secondary.indexOf("0") === 0;
        return !isNaN(this.anagrafica.tel_number_secondary) && !hasPrefix;
      }
      return false;
    },
    nazioniOptions() {
      if (this.nazioni && this.nazioni.length > 0) {
        return [
          { value: null, text: "Seleziona Nazione" },
          { value: "italia", text: "Italia" },
        ].concat(
          this.nazioni.map((nazione) => {
            return {
              value: nazione.nazione,
              text: nazione.nazione
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
            };
          })
        );
      } else
        return [
          {
            value: null,
            text: "Seleziona Nazione",
          },
        ];
    },
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fillOthers() {
      try {
        var cf = this.anagrafica.cf;
        let getYear = cf.substr(6, 2);
        let getMonth = this.cfMonths[cf.substr(8, 1)];
        let getDay = parseInt(cf.substr(9, 2));
        let day = getDay > 31 ? getDay - 40 : getDay;
        this.anagrafica.gender = getDay > 31 ? "femminile" : "maschile";

        let currentYear = moment().year() - 2000;
        let yearPrefix =
          parseInt(getYear) >= 0 && parseInt(getYear) <= currentYear
            ? "20"
            : "19";
        var stringBDate = yearPrefix + getYear + "/" + getMonth + "/" + day;
        var isDateValid = moment(stringBDate).isValid();
        this.anagrafica.birthday = moment(stringBDate).format("yyyy-MM-DD");

        if (cf.substr(11, 1) === "Z") {
          this.anagrafica.nazione_nascita = this.nazioni.find(
            (nazione) => nazione.codice === cf.substr(11, 4)
          )?.nazione;
        } else {
          this.anagrafica.nazione_nascita = "italia";
          this.searchComuneViaCCatastale(cf.substr(11, 4));
        }
        return isDateValid;
      } catch (err) {
        return false;
      }
    },
    OnSubmit() {
      //console.log(this.form);
      if (!this.validNumber) {
        return false;
      }
      // if (this.form.nomail && !this.validSecondaryNumber) {
      //   return false;
      // }
      if (this.form) {
        const self = this;
        this.form.anagrafica = this.anagrafica;
        supportService
          .arruolaPaziente(this.form)
          .then((res) => {
            window.scrollTo(0, 0);
            self.successAlert("Arruolamento completato con successo");
            setTimeout(function () {
              self.$emit("arruolamento-completed", res.data.data.hashid);
              self.$emit("arruolamento-done", res.data.data);
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
            var mesg = "Non è stato possibile completare l'operazione";
            if (error.status == 422) {
              mesg = "";
              Object.keys(error.data.errors).map(function (el) {
                mesg += error.data.errors[el];
              });
            } else if (error.status == 400) {
              mesg = error.data.message;
            }
            window.scrollTo(0, 0);
            self.errorAlert(mesg);
          });
      }
    },
    setDomicilioData() {
      if (this.selected.comune) {
        this.anagrafica.comune = this.selected.comune.comune;
        this.anagrafica.provincia = this.selected.comune.provincia;
        this.anagrafica.regione = this.selected.comune.regione;
      }
    },
    findComune(comune) {
      if (this.anagrafica.comune) {
        if (comune.comune === this.anagrafica.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    searchComuneViaCCatastale(codice) {
      const self = this;
      utilityService
        .searchComuneViaCodiceCatastale(codice)
        .then(function (res) {
          console.log(res.data.data);
          if (res.data.data) {
            self.selected.regione = res.data.data.regione;
            self.getProvince(res.data.data);
            self.selected.provincia = res.data.data.provincia;
            self.getComuni(res.data.data);
            self.selected.comune = res.data.data;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getNazioni() {
      var self = this;
      utilityService
        .nazioni()
        .then((res) => {
          self.nazioni = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getRegioni();
    if (this.params) {
      this.form.email = this.params.email;
      this.anagrafica.cf = this.params.cf;
    }
    this.getNazioni();
  },
};
</script>
<style>
div.card-header {
  background-color: #fff !important;
}

.nav-item > a.bg-white:hover {
  background-color: #fff !important;
}

.info_icon {
  position: absolute;
  left: 3.5rem;
  color: transparent !important;
  cursor: pointer;
}

/* .fs-5 {
  font-size: 89%;
  font-weight: 700;
} */
.informativa__p {
  overflow-y: scroll;
  padding: 0 5px;
  max-height: 250px;
}
</style>
