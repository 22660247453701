var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3"},[(_vm.template)?_c('div',{staticClass:"fw-bolder"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendForm($event)}}},[_c('b-row',{staticClass:"align-items-end",attrs:{"cols":"1"}},[_c('transition-group',{attrs:{"name":"list","mode":"out-in","appear":""}},[_vm._l((_vm.template.template.domande),function(domanda,ind){return _c('b-row',{key:ind,attrs:{"cols":"1","cols-md":"2"}},[_c('b-col',{staticClass:"text-justify"},[_c('label',{attrs:{"for":'q_' + ind}},[_vm._v(_vm._s(ind + 1 + ". " + domanda.q)+" "),(domanda.h)?_c('span',{staticClass:"mb-0 text-primary"},[_vm._v(" - domanda per l'operatore")]):_vm._e()])]),_c('b-col',{staticClass:"text-justify"},[_c('b-form-group',{key:'fgr_' + ind},[(domanda.o && domanda.o === 'file')?_c('div',{staticClass:"align-self-center"},[_c('SurveyUploader',{on:{"upload-result":function($event){return _vm.handleUpload($event, ind)},"remove-result":function($event){return _vm.removeUpload(ind)}}}),(!_vm.template.template.domande[ind].a)?_c('b-button',{staticClass:"mt-3",attrs:{"variant":"secondary fw-bolder"},on:{"click":function($event){_vm.template.template.domande[ind].a =
                        'immagine non allegata'}}},[_vm._v("Salta")]):_vm._e()],1):(domanda.cb && domanda.o)?[_c('b-form-group',{attrs:{"label":"Seleziona almeno una opzione*","label-class":domanda.r ? 'fw-bolder underlined' : 'd-none'}},[_c('b-form-checkbox-group',_vm._b({attrs:{"options":domanda.o,"name":'checkboxquestions' + ind,"label":"Scegli almeno una opzione*","label-class":"fw-bolder"},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}},'b-form-checkbox-group',
                        domanda.r && domanda.a.length === 0
                          ? { required: true }
                          : {}
                      ,false))],1),(domanda.a.includes('altro'))?_c('b-form-input',{staticClass:"shadow_6 align-self-center border-bottom border-primary",attrs:{"placeholder":"altra motivazione...","type":"text","required":domanda.r},model:{value:(_vm.addendums[ind]),callback:function ($$v) {_vm.$set(_vm.addendums, ind, $$v)},expression:"addendums[ind]"}}):_vm._e()]:(domanda.o)?[_c('b-form-select',{staticClass:"shadow_6 align-self-center",attrs:{"name":'q_' + ind,"required":domanda.r,"disabled":domanda.k && _vm.readOnlySelect.includes(domanda.k)
                        ? true
                        : false},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}},_vm._l((domanda.o),function(opt){return _c('b-form-select-option',{key:opt,attrs:{"value":opt}},[_vm._v(_vm._s(opt)+" ")])}),1),(domanda.lt && domanda.a === 'sì')?_c('span',{staticClass:"point pt-2"},[_c('a',{staticClass:"text-prim-grad-1",attrs:{"href":domanda.lt,"target":"_blank"}},[_vm._v(" "+_vm._s(domanda.lv)+" ")])]):_vm._e()]:(domanda.d)?[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('label',{attrs:{"for":'q_' + ind + '_min'}},[_vm._v("Min.")]),_c('b-form-input',{staticClass:"shadow_6 mb-2",attrs:{"type":"number","step":"1","max":"400","name":'q_' + ind + '_min',"required":domanda.r !== undefined ? domanda.r : true,"change":_vm.setAnswer(_vm.minP[ind], _vm.maxP[ind], ind)},model:{value:(_vm.minP[ind]),callback:function ($$v) {_vm.$set(_vm.minP, ind, $$v)},expression:"minP[ind]"}})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('label',{attrs:{"for":'q_' + ind + '_max'}},[_vm._v("Max.")]),_c('b-form-input',{staticClass:"shadow_6 mb-2",attrs:{"type":"number","step":"1","max":"400","name":'q_' + ind + '_max',"required":domanda.r !== undefined ? domanda.r : true,"change":_vm.setAnswer(_vm.minP[ind], _vm.maxP[ind], ind)},model:{value:(_vm.maxP[ind]),callback:function ($$v) {_vm.$set(_vm.maxP, ind, $$v)},expression:"maxP[ind]"}})],1)],1)]:_c('b-form-input',{staticClass:"shadow_6 align-self-center",attrs:{"placeholder":"...","type":"text","name":'q_' + ind,"required":domanda.r},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}})],2)],1)],1)}),_c('b-col',{key:"btsubmt",staticClass:"my-4 py-2"},[(_vm.loading)?_c('b-spinner'):_c('b-button',{attrs:{"type":"submit","variant":"primary primary_gradient text-white fw-bolder spacing_1"}},[_vm._v(" Invia")])],1)],2)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }