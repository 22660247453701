<template>
  <div class="pb-3">
    <div v-if="template" class="">
      <b-form @submit.prevent="sendForm">
        <b-row cols="1" class="align-items-end">
          <transition-group name="list" mode="out-in" appear>
            <b-row
              v-for="(domanda, ind) in template.template.domande"
              :key="ind"
              class="mb-3"
            >
              <b-col md="10" lg="6" xl="5" cols="12" class="text-justify">
                <h6 :for="'q_' + ind">
                  <span class="text-secondary"> {{ ind + 1 }}.</span>
                  {{ domanda.q }}
                  <span v-if="domanda.h" class="mb-0 text-secondary">
                    - domanda per l'operatore</span
                  >
                </h6>
              </b-col>
              <b-col
                :md="domanda.o === 'ricognizione' ? 12 : 8"
                :lg="domanda.o === 'ricognizione' ? 12 : 6"
                :xl="domanda.o === 'ricognizione' ? 12 : 7"
                cols="12"
                class="text-justify"
              >
                <template v-if="domanda.t">
                  <!-- questionario con definizione di tipo input -->
                  <b-form-group :key="'fgr_' + ind">
                    <template v-if="domanda.t === 'text'">
                      <b-form-input
                        class="align-self-center shadow_6"
                        placeholder="..."
                        type="text"
                        :name="'q_' + ind"
                        v-model="domanda.a"
                        v-bind="
                          domanda.r && domanda.i !== false ? { required: true } : {}
                        "
                      ></b-form-input>
                    </template>
                    <template v-else-if="domanda.t === 'select'">
                      <b-form-select
                        class="shadow_6 align-self-center"
                        v-model="domanda.a"
                        :name="'q_' + ind"
                        required
                      >
                        <b-form-select-option
                          v-for="opt in domanda.o"
                          :key="opt"
                          :value="opt"
                          >{{ opt }}
                        </b-form-select-option>
                        <!-- <b-form-select-option :value="null" disabled>seleziona</b-form-select-option> -->
                      </b-form-select>
                    </template>
                    <template v-else-if="domanda.t === 'select-multi'">
                      <b-form-tags
                        :name="'q_' + ind"
                        v-model="domanda.a"
                        size="lg"
                        class="mb-2"
                        add-on-change
                        no-outer-focus
                        @focus="hasFocus = ind"
                        @focusin="hasFocus = ind"
                      >
                        <template
                          v-slot="{
                            tags,
                            inputAttrs,
                            inputHandlers,
                            disabled,
                            removeTag,
                            addTag
                          }"
                        >
                          <b-form-select
                            class="shadow_6 align-self-center"
                            v-bind="inputAttrs"
                            v-on="inputHandlers"
                            :disabled="disabled || domanda.o.length === 0"
                            :options="domanda.o"
                          >
                            <template #first>
                              <!-- This is required to prevent bugs with Safari -->
                              <option disabled value="">
                                scegli una o più opzioni...
                              </option>
                            </template>
                          </b-form-select>
                          <ul
                            v-if="tags.length > 0"
                            class="list-inline d-inline-block mt-2"
                          >
                            <li
                              v-for="tag in tags"
                              :key="tag"
                              class="list-inline-item"
                            >
                              <b-form-tag
                                @remove="removeTag(tag)"
                                :title="tag"
                                :disabled="disabled"
                                variant="secondary"
                                >{{ tag }}</b-form-tag
                              >
                            </li>
                          </ul>
                        </template>
                      </b-form-tags>

                      <template
                        v-if="
                          Array.isArray(domanda.a) &&
                          domanda.a.includes('altro')
                        "
                      >
                        <b-input-group v-if="hasFocus === ind">
                          <b-form-input
                            class="align-self-center input_with_append shadow_6"
                            placeholder="altra opzione personalizzata"
                            type="text"
                            :name="'qti_' + ind"
                            v-model="tempTag"
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button @click="addCustom(ind)"
                              >aggiungi altro</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                      </template>
                    </template>
                    <template v-else-if="domanda.t === 'date'">
                      <b-form-input
                        class="align-self-center shadow_6"
                        placeholder="GG/MM/AAAA"
                        type="date"
                        :name="'q_' + ind"
                        v-model="domanda.a"
                        v-bind="
                          domanda.r && domanda.i !== false ? { required: true } : {}
                        "
                        :min="dateMin(domanda)"
                        :max="dateMax(domanda)"
                      ></b-form-input>
                    </template>
                  </b-form-group>
                </template>
                <template v-else-if="domanda.o === 'ricognizione'">
                  <b-row
                    class="my-2 col-12"
                    id="verifica_terapie_col"
                    v-if="domanda.o && domanda.o === 'ricognizione'"
                  >
                    <b-row align-v="stretch">
                      <b-col
                        class="text-justify fw-bolder text-medium-grey bg-light general_border"
                      >
                        <p class="my-2 py-2 fs-6">
                          Verifica ed aggiorna le terapie del paziente cliccando
                          su "Verifica terapia paziente". Dopo aver aggiornato
                          le terapie, completa ciascuna scheda con le
                          informazioni mancanti.
                        </p>
                      </b-col>
                      <b-col class="align-self-center">
                        <b-button @click="$bvModal.show('terapie_paziente')"
                          >Verifica terapie paziente</b-button
                        ></b-col
                      >
                    </b-row>
                    <template v-if="schemaRicognizione">
                      <b-row
                        v-for="(therapy, index) in schemaRicognizione"
                        :key="therapy.hashid"
                        class="px-2 mt-2"
                      >
                        <h6
                          class="text-left bg-prim-grad-1 text-white fw-bolder p-2 w-100"
                        >
                          <span class="bg-secondary p-2"
                            >Terapia {{ index + 1 }}.</span
                          >
                          {{ therapy[0].a }}
                        </h6>
                        <b-col>
                          <b-row cols="1" cols-md="2">
                            <b-col v-for="(field, position) in therapy">
                              <b-form-group class="text-left">
                                <label>{{ field.q }}:</label>
                                <template v-if="field.t === 'select'">
                                  <b-form-select
                                    class="shadow_6 align-self-center"
                                    v-model="
                                      schemaRicognizione[index][position].a
                                    "
                                    :name="'ric_' + index"
                                    v-bind="
                                      field.r && field.i !== false
                                        ? { required: true }
                                        : {}
                                    "
                                  >
                                    <b-form-select-option
                                      v-for="opt in field.o"
                                      :key="opt"
                                      :value="opt"
                                      >{{ opt }}
                                    </b-form-select-option>
                                  </b-form-select>
                                </template>
                                <template v-else-if="field.t === 'datetime'">
                                  <!-- <b-form-input
                                class="shadow_6"
                                type="date"
                                placeholder="GG/MM/AAAA hh:mm"
                                :name="'ric_date_' + index"
                                v-model="dateInDatetime"
                                :readonly="field.i == false"
                              ></b-form-input>
                              <b-form-input
                                class="shadow_6"
                                type="time"
                                placeholder="GG/MM/AAAA hh:mm"
                                :name="'ric_time_' + index"
                                @input="
                                  dateTimeTimeInput($event, index, position)
                                "
                                :readonly="field.i == false"
                              ></b-form-input> -->
                                  <b-form-input
                                    class="shadow_6"
                                    type="text"
                                    placeholder="GG/MM/AAAA hh:mm"
                                    :name="'ric_' + index"
                                    v-model="
                                      schemaRicognizione[index][position].a
                                    "
                                    :readonly="field.i == false"
                                    v-bind="
                                      field.r && field.i !== false
                                        ? { required: true }
                                        : {}
                                    "
                                  ></b-form-input>
                                </template>
                                <b-form-input
                                  v-else
                                  class="shadow_6"
                                  :type="field.t"
                                  v-model="
                                    schemaRicognizione[index][position].a
                                  "
                                  :readonly="field.i == false"
                                  v-bind="
                                    field.r && field.i !== false
                                      ? { required: true }
                                      : {}
                                  "
                                  :name="'ric_' + index"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </template>
                    <b-spinner v-else></b-spinner>
                  </b-row>
                </template>
                <template v-else>
                  <b-form-group :key="'fgr_' + ind">
                    <div
                      v-if="domanda.o && domanda.o === 'file'"
                      class="align-self-center"
                    >
                      <b-button
                        class="general_border"
                        size="sm"
                        v-if="domanda.fm || !domanda.a || !domanda.a.length"
                        @click="openUploadModal(domanda)"
                      >
                        Aggiungi file
                        <b-icon icon="plus"></b-icon>
                      </b-button>
                      <b-row
                        v-for="(element, fileIndex) in domanda.a"
                        :key="fileIndex"
                        class="mt-2 d-flex align-items-center"
                      >
                        <b-col class="mb-0 col-2"
                          >File {{ fileIndex + 1 }}</b-col
                        >
                        <b-col
                          class="d-flex col-10 pl-0 justify-content-start text-left align-items-center"
                        >
                          <b-button
                            size="sm"
                            variant="outline-primary general_border"
                            class="mr-3"
                            @click="downloadResource(element)"
                          >
                            Scarica
                            <b-icon icon="download"></b-icon>
                          </b-button>
                          <b-button
                            size="sm"
                            class="mr-3"
                            variant="outline-danger general_border"
                            @click="deleteResource(element, domanda)"
                          >
                            Elimina
                          </b-button>

                          <span class="ml-3 point" @click="openShowModal(hist)">
                            <b-icon
                              stacked
                              icon="eye-fill"
                              scale="0.5"
                              variant="secondary"
                            ></b-icon>
                          </span>
                        </b-col>
                      </b-row>
                    </div>
                    <template v-else-if="domanda.cb && domanda.o">
                      <b-form-group
                        label="Seleziona almeno una opzione*"
                        :label-class="
                          domanda.r ? 'fw-bolder underlined' : 'd-none'
                        "
                      >
                        <b-form-checkbox-group
                          v-model="domanda.a"
                          :options="domanda.o"
                          :name="'checkboxquestions' + ind"
                          v-bind="
                            domanda.r && domanda.a.length === 0
                              ? { required: true }
                              : {}
                          "
                          label="Scegli almeno una opzione*"
                          label-class="fw-bolder"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <b-form-input
                        placeholder="altra motivazione..."
                        type="text"
                        class="shadow_6 align-self-center border-bottom border-primary"
                        v-model="addendums[ind]"
                        v-if="domanda.a.includes('altro')"
                        :required="domanda.r"
                      ></b-form-input>
                    </template>

                    <b-form-select
                      class="align-self-center shadow_6"
                      v-else-if="domanda.o"
                      v-model="domanda.a"
                      :name="'q_' + ind"
                      v-bind="
                        domanda.r && domanda.i !== false
                          ? { required: true }
                          : {}
                      "
                    >
                      <b-form-select-option
                        v-for="opt in domanda.o"
                        :key="opt"
                        :value="opt"
                        >{{ opt }}
                      </b-form-select-option>
                    </b-form-select>
                    <b-form-input
                      v-else
                      class="align-self-center shadow_6"
                      placeholder="..."
                      type="text"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      v-bind="
                        domanda.r && domanda.i !== false
                          ? { required: true }
                          : {}
                      "
                    ></b-form-input>
                  </b-form-group>
                </template>
              </b-col>
              <b-col
                cols="12"
                v-if="
                  domanda.o &&
                  domanda.o === 'file' &&
                  domanda.r &&
                  (!domanda.a || !domanda.a.length)
                "
              >
                <p class="text-danger text-left">
                  Questo campo è obbligatorio.
                </p>
              </b-col>
            </b-row>
            <b-col class="my-4 py-2" key="btsubmt">
              <b-button
                type="button"
                variant="outline-danger fw-bolder"
                class="mx-1"
                @click="$router.back()"
              >
                Annulla</b-button
              >
              <b-button
                type="submit"
                variant="primary primary_gradient fw-bolder"
                class="mx-1"
                :disabled="filesAreMissing"
              >
                Salva Modifiche</b-button
              >
            </b-col>
          </transition-group>
        </b-row>
      </b-form>
    </div>
    <b-modal
      id="delete-resource"
      title="Elimina risorsa"
      button-size="sm"
      @ok="doDelete"
      @cancel="resetDeleteFile()"
      cancel-variant="secondary px-3 rounded-pill font-weight-bolder"
      ok-variant="outline-danger px-3 font-weight-bolder rounded-pill"
      cancel-title="Annulla"
      ok-title="Elimina"
      footer-border-variant="white"
      centered
    >
      <h6>Sei sicuro di voler eliminare la risorsa selezionata?</h6>
    </b-modal>
    <b-modal
      id="terapie_paziente"
      hide-footer
      centered
      scrollable
      size="xl"
      title="Aggiorna terapie paziente"
      @hide="fetchUserTherapies"
    >
      <ArmadiettoComponent
        :subject="{ type: 'user_in_app', hashid: patient.hashid }"
        :ricognizione="true"
      ></ArmadiettoComponent>
    </b-modal>
    <b-modal
      id="upload-modal"
      title="Carica file"
      size="lg"
      hide-footer
      centered
    >
      <SurveyUploader
        v-if="uploadingToDomanda"
        @upload-result="handleUpload($event)"
        @remove-result="removeUpload(ind)"
        :accepts="uploadingToDomanda.fa"
        :isMulti="uploadingToDomanda.fm"
      />
    </b-modal>
  </div>
</template>
<script>
import SurveyUploader from "@/components/utilities/SurveyUploader.vue"
import { utilityService, cittadinoService } from "@/_services"
import ArmadiettoComponent from "@/components/profile/sanitario/ArmadiettoComponent.vue"
import { mapActions } from "vuex"
import * as moment from "moment/moment"

export default {
  name: "EditQuestionarioCompilato",
  props: ["filledSurvey", "originalSurvey", "patient"],
  components: { SurveyUploader, ArmadiettoComponent },
  data() {
    return {
      template: null,
      consent: false,
      proceed: false,
      file: null,
      addendums: [],
      tempTag: null,
      hasFocus: null,
      resourceToDelete: {
        hash: null,
        domanda: null
      },
      filesAreMissing: false,
      uploadingToDomanda: null,
      lastUpdatedHash: null,
      schemaRicognizione: null,
      surveyTemplateRicognizione: null,
      hasRicognizione: false
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    openUploadModal(domanda) {
      this.uploadingToDomanda = domanda
      this.$bvModal.show("upload-modal")
    },
    resetDeleteFile() {
      this.resourceToDelete.hash = null
      this.resourceToDelete.domanda = null
    },
    // deleteResource(element, domanda) {
    //   console.log("deleting resource", element)

    //   const newArray = domanda.a.filter((item) => item !== element)

    //   Vue.set(domanda, "a", newArray)
    // },
    deleteResource(element, domanda) {
      this.resourceToDelete.hash = element
      this.resourceToDelete.domanda = domanda
      this.$bvModal.show("delete-resource")
    },
    doDelete() {
      const index = this.resourceToDelete.domanda.a.indexOf(
        this.resourceToDelete.hash
      )
      this.$delete(this.resourceToDelete.domanda.a, index)
      this.checkRequiredFiles()

      this.$forceUpdate()
    },
    checkRequiredFiles() {
      let check = false

      this.template.template.domande.forEach((domanda) => {
        if (domanda.o === "file" && domanda.r === true) {
          if (!Array.isArray(domanda.a) || domanda.a.length === 0) {
            check = true
          }
        }
      })

      this.filesAreMissing = check
    },
    downloadResource(resourceHash) {
      var self = this
      utilityService
        .downloadResource(resourceHash)
        .then(function (response) {
          // console.log("response", response.headers)
          let contentType = response.headers["content-type"]
          let blob = new Blob([response.data], { type: contentType })
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)

          let contentDisposition = response.headers["content-disposition"]
          let fileName = contentDisposition.split("filename=")[1].split(";")[0]

          // Rimozione di eventuali underscore
          fileName = fileName.replace(/^_+|_+$/g, "").replace(/\"/g, "")

          link.download = fileName
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          self.errorAlert("Non è stato possibile scaricare la risorsa")
        })
    },

    onFileChange(e) {
      this.file = e.target.files[0]
    },
    sendForm() {
      if (!this.validateSurvey(this.template.template)) {
        return
      }

      if (this.addendums.length > 0) {
        this.addendums.forEach(function (element, ind) {
          if (
            this.template.template.domande[ind] &&
            this.template.template.domande[ind].a.includes("altro")
          ) {
            const altroIndex = this.template.template.domande[ind].a.indexOf(
              "altro"
            );
            this.template.template.domande[ind].a[altroIndex] =
              "altro: " + element;
          }
        });
      }

      this.template.template.ricognizione = this.schemaRicognizione
      this.$emit("filled-form", this.template)
    },
    validateSurvey(template) {
      for (let ind = 0; ind < template.domande.length; ind++) {
        const isRicognizione = template.domande[ind].o === "ricognizione"

        if (
          (template.domande[ind].r === true || isRicognizione) &&
          !template.domande[ind].a &&
          (template.domande[ind].o !== "ricognizione" ||
            this.schemaRicognizione === null ||
            (Array.isArray(this.schemaRicognizione) &&
              this.schemaRicognizione.length === 0))
        ) {
          const elementId = "q_" + ind
          this.errorAlert(
            "Compila il campo obbligatorio: " + template.domande[ind].q
          )
          const element = document.getElementById(elementId)
          if (element) {
            element.scrollIntoView()
          } else if (template.domande[ind].o === "ricognizione") {
            const element = document.getElementById("verifica_terapie_col")
            const offset = 220
            const bodyRect = document.body.getBoundingClientRect().top
            const elementRect = element.getBoundingClientRect().top
            const elementPosition = elementRect - bodyRect
            const offsetPosition = elementPosition - offset

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
            })
          }
          this.loading = false
          return false
        }
      }
      //if questionario has_ricognizione
      if (this.hasRicognizione) {
        for (let ind = 0; ind < template.ricognizione.length; ind++) {
          for (let pos = 0; pos < template.ricognizione[ind].length; pos++) {
            const doCheck =
              pos <= 6 || (pos > 6 && template.ricognizione[ind][6].a === "sì")
            if (
              doCheck &&
              template.ricognizione[ind][pos].r === true &&
              !template.ricognizione[ind][pos].a
            ) {
              const elementId = "ric_" + ind + "_" + pos
              this.errorAlert(
                "Compila per la terapia " +
                  (ind + 1) +
                  " il campo obbligatorio: " +
                  template.ricognizione[ind][pos].q
              )
              document.getElementById(elementId).scrollIntoView()
              this.loading = false
              return false
            }
          }
        }
      }

      return true
    },
    handleUpload(data) {
      this.$bvModal.hide("upload-modal")
      const newHashids = []
      if (Array.isArray(data)) {
        data.forEach((el) => {
          newHashids.push(el.hashid)
        })
      } else {
        newHashids.push(data.hashid)
      }
      console.log("data", data)
      console.log("newHashids", newHashids)
      if (this.uploadingToDomanda.a == null) {
        this.uploadingToDomanda.a = []
      }
      this.uploadingToDomanda.a.push(...newHashids)
      this.lastUpdatedHash = data.hashid
      this.checkRequiredFiles()
    },
    removeUpload() {
      const index = this.uploadingToDomanda.a.indexOf(this.lastUpdatedHash)
      this.$delete(this.uploadingToDomanda.a, index)
      this.checkRequiredFiles()
    },
    addCustom(ind) {
      this.template.template.domande[ind].a.push(this.tempTag)
      this.tempTag = null
    },
    fetchUserTherapies() {
      const self = this
      var filters = {
        user: this.patient.hashid,
        scope: "ricognizione"
      }
      // console.log("FETCHING USER THERAPIES", filters)
      cittadinoService
        .getTherapies(filters)
        .then(function (response) {
          self.therapies = response.data.data
          // console.log("TERAPIE", self.therapies)
          self.setupRicognizioneSchema(response.data.data)
        })
        .catch(function (error) {
          console.log("error", error)
          self.errorAlert("Non è stato possibile recuperare le terapie")
        })
    },
    setupRicognizioneSchema(terapie) {
      const ricognizione = JSON.parse(this.originalSurvey.template).ricognizione
      // conosole.log("")
      if (ricognizione) {
        // console.log("RICOGNIZIONE", ricognizione)
        this.schemaRicognizione = null
        const self = this
        var arraySchema = []
        const terLen = terapie.length
        for (let i = 0; i < terLen; i++) {
          // console.log(terapie[i].product_name);
          let schemaTemplate = []
          Object.keys(ricognizione).map(function (el) {
            var answr = null
            if (ricognizione[el].k) {
              answr = terapie[i][ricognizione[el].k]
            }
            let newObj = {}

            if (ricognizione[el].k !== undefined) newObj.k = ricognizione[el].k
            if (ricognizione[el].i !== undefined) newObj.i = ricognizione[el].i
            if (ricognizione[el].t !== undefined) newObj.t = ricognizione[el].t
            if (ricognizione[el].o !== undefined) newObj.o = ricognizione[el].o
            if (ricognizione[el].q !== undefined) newObj.q = ricognizione[el].q
            if (ricognizione[el].r !== undefined) newObj.r = ricognizione[el].r
            if (ricognizione[el].y !== undefined) newObj.y = ricognizione[el].y
            
            newObj.a = answr
             
            schemaTemplate.push(newObj)
          })
          arraySchema.push(schemaTemplate)
        }
        this.schemaRicognizione = arraySchema
        this.populateSchemaRicognizioneWithPreviousData()
      }
    },
    populateTemplate() {
      // Il modifica questionario parte dal questionario precedente
      this.template = JSON.parse(JSON.stringify(this.originalSurvey))
      this.template.template = JSON.parse(this.originalSurvey.template)
      const filledSurvey = JSON.parse(this.filledSurvey.response)

      // Popola le risposte dal filledSurvey al template se la domanda ha lo stesso testo
      for (let i = 0; i < this.template.template.domande.length; i++) {
        // Verifico se c'è una sola corrispondenza per quel testo di domande
        let matchingQuestions = filledSurvey.domande.filter(
          (el) => el.q === this.template.template.domande[i].q
        )

        let filledAnswer = null

        if (matchingQuestions.length === 1) {
          // Se c'è una sola corrispondenza univoca, la domanda è quella, copia il testo
          filledAnswer = matchingQuestions[0]
          // console.log(
          //   "FOUND UNIQUE FILLED ANSWER FOR",
          //   this.template.template.domande[i].q
          // )
        } else if (matchingQuestions.length > 1) {
          // console.log(
          //   "CERCO LE RISPOSTE PER",
          //   this.template.template.domande[i].q
          // )
          // Se ci sono più corrispondenze, controlliamo anche che ci sia una domanda per cui l'indice i è uguale a quello della domanda e la precedente, se esiste, abbia lo stesso testo
          let matchingQuestionsWithIndex = filledSurvey.domande.filter(
            (el, index) =>
              el.q === this.template.template.domande[i].q &&
              index === i &&
              (index === 0 ||
                filledSurvey.domande[index - 1].q ===
                  this.template.template.domande[i - 1].q)
          )
          // console.log(
          //   "MATCHING QUESTIONS WITH INDEX",
          //   matchingQuestionsWithIndex
          // )

          if (matchingQuestionsWithIndex.length === 1) {
            filledAnswer = matchingQuestionsWithIndex[0]
          }
        }

        if (filledAnswer) {
          this.template.template.domande[i].a = filledAnswer.a
        } else {
          console.log("NO ANSWER FOR", this.template.template.domande[i].q)
        }
      }
    },
    populateSchemaRicognizioneWithPreviousData() {
      //if filled ricognizione is not null or is an array with length, populate each therapy data with previous data provided that therapy.product_aic is the same as already populated product_aic
      const filledSurveyRicognizione = JSON.parse(this.filledSurvey.response)
        .ricognizione
      if (filledSurveyRicognizione && filledSurveyRicognizione.length > 0) {
        if (
          Array.isArray(this.schemaRicognizione) &&
          this.schemaRicognizione.length > 0
        ) {
          // Loop through each element in schemaRicognizione
          this.schemaRicognizione.forEach((therapy) => {
            const productAic = therapy.find((el) => el.q === "AIC prodotto")?.a
            const posologia = therapy.find((el) => el.q.includes("posologia"))
              ?.a

            // Find the therapy in filledSurveyRicognizione where the productAic and posologia match
            const filledTherapy = filledSurveyRicognizione.find(
              (filledTherapy) => {
                const aicProdottoMatch =
                  filledTherapy.find((domanda) => domanda.q === "AIC prodotto")
                    ?.a === productAic

                const posologiaMatch =
                  filledTherapy.find((domanda) =>
                    domanda.q.includes("posologia")
                  )?.a === posologia

                // Check if both the "AIC prodotto" and "posologia" match
                return aicProdottoMatch && posologiaMatch
              }
            )

            if (filledTherapy) {
              // Populate where i = true
              therapy.forEach((field, index) => {
                if (field.i === true && filledTherapy[index]) {
                  field.a = filledTherapy[index].a
                }
              })
            }
          })
        }
      }
    },
    dateMin(domanda) {
      if (domanda.y && (domanda.y.min || domanda.y.min === 0)) {
        return moment()
          .subtract(domanda.y.min, "years")
          // .startOf("year")
          .format("YYYY-MM-DD")
      }
      return null
    },
    dateMax(domanda) {
      if (domanda.y && (domanda.y.max || domanda.y.max === 0)) {
        return moment()
          .subtract(domanda.y.max, "years")
          // .startOf("year")
          .format("YYYY-MM-DD")
      }
      return null
    }
  },
  created() {
    this.populateTemplate()

    this.hasRicognizione =
      this.template.has_ricognizione === 1 ||
      this.template.has_ricognizione === true

    if (this.originalSurvey.template.includes("ricognizione")) {
      this.fetchUserTherapies()
    }
  }
}
</script>
<style scoped>
.form-control[readonly] {
  background-color: #168bb412 !important;
}
</style>
