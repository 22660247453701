<template>
  <b-container>
    <b-row class="justify-content-start">
      <b-col class="col-12 col-md-5">
        <b-form-group
          label="Cerca utente per codice fiscale"
          label-for="cf"
          class="text-left fs-6 text-font-grey mx-auto"
        >
          <b-form-input
            class="my-3 shadow_6 text-uppercase"
            name="cf"
            type="text"
            placeholder="codice fiscale..."
            maxlength="16"
            minlength="16"
            v-model="params.cf"
            :state="params.cf == null ? null : validCf"
            @input="nullCf"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col
        class="col-12 col-md-6 col-lg-5 col-xl-4 align-self-end my-3 text-left"
      >
        <b-button
          variant="primary text-white fw-bolder spacing_1"
          :disabled="params.cf == null ? true : !validCf"
          @click="searchUser"
          >Cerca codice fiscale</b-button
        >
      </b-col>
    </b-row>
    <template v-if="!fullresponse">
      <p class="font-weight-bolder text-left">Oppure</p>
      <b-row class="justify-content-start">
        <b-col class="col-12 col-md-5">
          <b-form-group
            label="Cerca utente per email"
            label-for="email"
            class="text-left fs-6 text-font-grey mx-auto"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="email"
              type="email"
              placeholder="email..."
              v-model="params.email"
              :state="params.email == null ? null : validEmail"
              @input="nullEmail"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col
          class="col-12 col-md-6 col-lg-5 col-xl-4 align-self-end my-3 text-left"
        >
          <b-button
            variant="primary text-white fw-bolder spacing_1"
            :disabled="params.email == null ? true : !validEmail"
            @click="searchUser"
            >Cerca email</b-button
          >
        </b-col>
      </b-row>
    </template>
    <b-modal size="lg" id="member-found" centered hide-footer>
      <b-row v-if="!notFound && searchResult" class="justify-content-center">
        <b-col class="col-12 text-center my-3">
          <h5>
            <span> Trovato un utente con </span>
            <span v-if="params.email">
              email <strong>{{ params.email }}</strong>
            </span>
            <span v-if="params.cf">
              codice fiscale
              <strong class="text-uppercase">{{ params.cf }}</strong>
            </span>
            <br />
            <br />
            <span class="text-capitalize font-weight-bolder"
              >{{ searchResult.anagrafica.name }}
              {{ searchResult.anagrafica.surname }}</span
            >
          </h5>
        </b-col>
        <!-- <b-col class="col-12 text-left my-3">
          <h6>Azioni rapide:</h6>
          <b-button
            size="sm"
            variant="primary primary_gradient text-white fw-bolder mx-1"
            >Somministra questionario</b-button
          >
          <b-button size="sm" variant="outline-primary fw-bolder mx-1"
            >Inserisci terapia</b-button
          >
          <b-button size="sm" variant="outline-primary fw-bolder mx-1"
            >Inserisci prenotazione</b-button
          >
          <b-row class="mt-4">
            <b-form-group label="">
              <h6>Seleziona questionario</h6>
              <b-form-select>
                <b-form-select-option>Aderenza BPCO</b-form-select-option>
                <b-form-select-option>ECG</b-form-select-option>
                <b-form-select-option></b-form-select-option>
                <b-form-select-option></b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-row>
        </b-col> -->

        <div v-if="searchResult.is_my_patient">
          <b-col class="col-12 d-flex justify-content-center my-3">
            <b-button
              class="mx-2"
              variant="outline-danger fw-bolder"
              @click="$bvModal.hide('member-found')"
              >Annulla
            </b-button>
            <b-button
              class="mx-2"
              variant="primary text-white fw-bolder spacing_1"
              @click="sendInvite"
              >{{ btnText }}
            </b-button>
          </b-col>
        </div>
        <div v-else>
          <template v-if="!canProceed">
            <OtpValidation
              :user="searchResult"
              @otp-validated="handleOtpValidation($event)"
              class="text-center"
            >
              <template v-slot:instruction>
                <h6 class="text-justify p-3">
                  Per accedere alla scheda di questo paziente è necessario
                  ottenere il suo consenso.
                  <br />
                  Clicca su "invia codice di verifica" per inviare il codice OTP
                  di accesso via SMS al numero del paziente.
                </h6>
              </template>
            </OtpValidation>
            <div class="text-center">
              <p class="my-2">oppure</p>
              <b-button
                class="mx-3"
                variant="outline-info  fw-bolder"
                @click="$bvModal.hide('member-found')"
                >Annulla e torna indietro</b-button
              >
            </div>
          </template>
          <b-col v-else class="col-12 d-flex justify-content-center my-3">
            <b-button
              class="mx-2"
              variant="outline-danger fw-bolder"
              @click="$bvModal.hide('member-found')"
              >Annulla
            </b-button>
            <b-button
              class="mx-2"
              variant="primary text-white fw-bolder spacing_1"
              @click="sendInvite"
              >{{ btnText }}
            </b-button>
          </b-col>
        </div>
      </b-row>
      <b-row v-else class="col-12 text-center">
        <h5 class="w-100 text-center">Nessun utente trovato.</h5>
        <b-col class="col-12 d-flex justify-content-center my-3">
          <b-button
            class="mx-2"
            variant="outline-danger fw-bolder"
            @click="$bvModal.hide('member-found')"
            >Annulla
          </b-button>
          <b-button
            class="mx-2"
            variant="primary text-white fw-bolder spacing_1"
            @click="sendInvite"
            :disabled="!company.can_register"
            >Arruola</b-button
          >
        </b-col>
        <p class="text-danger text-center w-100" v-if="!company.can_register">
          La farmacia ha superato il limite di pazienti arruolabili.
        </p>
      </b-row>
    </b-modal>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { utilityService, companyService } from "@/_services";
import OtpValidation from "@/components/utilities/OtpValidation.vue";
export default {
  name: "SearchUtente",
  props: ["type", "fullresponse", "custombtn"],
  components: { OtpValidation },
  data() {
    return {
      params: {
        cf: null,
        email: null,
      },
      searchResult: null,
      notFound: false,
      canProceed: false,
      cfMonths: {
        A: "01",
        B: "02",
        C: "03",
        D: "04",
        E: "05",
        H: "06",
        L: "07",
        M: "08",
        P: "09",
        R: "10",
        S: "11",
        T: "12",
        a: "01",
        b: "02",
        c: "03",
        d: "04",
        e: "05",
        h: "06",
        l: "07",
        m: "08",
        p: "09",
        r: "10",
        s: "11",
        t: "12",
      },
    };
  },
  computed: {
    validEmail() {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.params.email.match(validRegex) ? true : false;
    },
    validCf() {
      // var validRegex = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      var validRegex = /^[a-zA-Z]{6}[\dLMNPQRSTUVlmnpqrstuv]{2}[ABCDEHLMPRSTabcdehlmprst][\dLMNPQRSTUVlmnpqrstuv]{2}[a-zA-Z][\dLMNPQRSTUVlmnpqrstuv]{3}[a-zA-Z]$/;
      // var validRegex = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;

      if (this.params.cf.length === 16) {
        return this.params.cf.match(validRegex) ? this.fillOthers() : false;
      }
      return this.params.cf.match(validRegex) ? true : false;
    },
    btnText() {
      if (this.custombtn) {
        return this.custombtn;
      }
      if (this.fullresponse) {
        return "Seleziona paziente";
      }
      return "Vai a scheda paziente";
    },
    ...mapState("company", ["company"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fillOthers() {
      try {
        var cf = this.params.cf;
        let getYear = cf.substr(6, 2);
        let getMonth = this.cfMonths[cf.substr(8, 1)];
        let getDay = parseInt(cf.substr(9, 2));
        let day = getDay > 31 ? getDay - 40 : getDay;
        let currentYear = moment().year() - 2000;
        let yearPrefix =
          parseInt(getYear) >= 0 && parseInt(getYear) <= currentYear
            ? "20"
            : "19";
        var stringBDate = yearPrefix + getYear + "/" + getMonth + "/" + day;
        return moment(stringBDate, "YYYY/MM/DD").isValid();
      } catch (err) {
        return false;
      }
    },
    searchUser() {
      this.notFound = false;
      this.searchResult = null;
      var self = this;
      utilityService
        .searchUser(this.params)
        .then(function (res) {
          self.searchResult = res.data.data;
          self.$bvModal.show("member-found");
        })
        .catch(function (err) {
          self.$bvModal.show("member-found");
          self.notFound = true;
        });
    },
    sendInvite() {
      if (this.searchResult) {
        this.$emit("invite-user", this.searchResult.hashid);
        this.$emit("select-user", this.searchResult);
      } else {
        if (!this.company.can_register) {
          return this.infoAlert(
            "La farmacia ha superato il limite di pazienti arruolabili"
          );
        }
        this.$emit("arruola-user", this.params);
      }
      this.$bvModal.hide("member-found");
    },
    nullCf() {
      if (this.params.cf.length == 0) {
        this.params.cf = null;
      } else {
        this.params.email = null;
      }
    },
    nullEmail() {
      if (this.params.email.length == 0) {
        this.params.email = null;
      } else {
        this.params.cf = null;
      }
    },
    handleOtpValidation(isValid) {
      this.canProceed = isValid;
      if (isValid) {
        const self = this;
        companyService
          .setAssistito(this.searchResult.hashid)
          .then(function (res) {
            self.sendInvite();
          })
          .catch(function (res) {
            self.sendInvite();
          });

        // this.sendInvite();
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", function (event) {
      // if (event.keyCode == 13 || event.keyCode == 17 || event.keyCode == 74)
      if (event.keyCode == 13 || event.keyCode == 17) {
        event.preventDefault();
      }
    });
  },
};
</script>
