var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user.permessi.includes('gestire attività e questionari'))?_c('div',{staticClass:"py-3"},[(_vm.loading)?_c('div',{staticClass:"col-12 text-center"},[_c('b-spinner')],1):_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('router-link',{staticClass:"text-primary no__hover underlined",attrs:{"to":{ name: 'attivita', params: { section: 'questionari' } }}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill","variant":"primary"}}),_vm._v(" Questionari ")],1),(_vm.selectedUser)?_c('router-link',{staticClass:"text-secondary no__hover underlined",attrs:{"to":{
          name: 'scheda paziente',
          params: { hash: _vm.selectedUser.hashid }
        },"target":"_blank"}},[_vm._v(" Scheda Paziente "),_c('b-icon',{attrs:{"icon":"arrow-right-circle-fill","variant":"secondary"}})],1):_vm._e()],1),(_vm.survey && !_vm.complete)?_c('div',{staticClass:"py-3"},[_c('h4',{staticClass:"text-left pb-1"},[_vm._v("Modifica "+_vm._s(_vm.survey.survey_name))]),_c('EditQuestionarioCompilato',{attrs:{"filledSurvey":_vm.survey,"originalSurvey":_vm.originalSurvey,"patient":_vm.surveyPatient},on:{"filled-form":function($event){return _vm.submitForm($event)}}})],1):(_vm.complete)?_c('div',[_c('h2',{staticClass:"fw-bolder"},[_vm._v("Operazione completata.")]),(_vm.editedSurvey && _vm.editedSurvey.score !== undefined)?_c('h5',{staticClass:"text-center mt-3"},[_vm._v(" Punteggio totalizzato: "+_vm._s(_vm.editedSurvey.score)+" ")]):_vm._e(),(_vm.editedSurvey.reference)?_c('div',{staticClass:"text-left"},[(_vm.survey.type !== 0)?[_c('h5',{staticClass:"text-ptim-grad-1"},[_vm._v("Interpretazione punteggio:")]),_vm._l((_vm.editedSurvey.reference),function(reference,ind){return [_c('h6',{staticClass:"fw-bolder"},[_vm._v(" Totale "),(reference.min_ref === reference.max_ref)?_c('span',[_vm._v(" "+_vm._s(reference.max_ref * 1))]):_c('span',[_vm._v(" "+_vm._s(reference.min_ref * 1)+" - "+_vm._s(reference.max_ref * 1)+" ")]),(reference.description)?[_c('span',{staticClass:"font-italic",domProps:{"innerHTML":_vm._s('<span>' + reference.description + '</span>')}})]:_vm._e()],2)]})]:[_c('h5',{staticClass:"text-ptim-grad-1 mt-4 pt-4"},[_vm._v(" Interpretazione punteggio: ")]),_vm._l((_vm.editedSurvey.reference),function(reference,ind){return [(
                _vm.editedSurvey.score >= reference.min_ref &&
                _vm.editedSurvey.score <= reference.max_ref
              )?_c('h6',{staticClass:"fw-bolder"},[(reference.description)?[_c('span',{staticClass:"font-italic",domProps:{"innerHTML":_vm._s('<span>' + reference.description + '</span>')}})]:_vm._e()],2):_vm._e()]})]],2):_vm._e(),(_vm.editedSurvey.prevs)?_c('div',{staticClass:"text-left"},[_c('h5',{staticClass:"text-ptim-grad-1"},[_vm._v("Storico punteggi:")]),_vm._l((_vm.editedSurvey.prevs),function(prevScore,ind){return [_c('h6',{staticClass:"fw-bolder"},[_vm._v(" Punteggio totalizzato il "+_vm._s(_vm._f("moment")(prevScore.created_at,"DD/MM/YYYY"))+": "+_vm._s(prevScore.value * 1)+" ")])]})],2):_vm._e(),(_vm.editedSurvey && _vm.editedSurvey.survey.type === 2)?[_vm._m(0),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-button',{attrs:{"variant":"secondary text-white  spacing_1"},on:{"click":function($event){return _vm.$router.push({
                  name: 'attivita',
                  params: {
                    section: 'questionario',
                    action: 'report',
                    quest: _vm.editedSurvey.survey.hashid
                  }
                })}}},[_vm._v("Torna ai report")])],1),_c('b-col',[_c('b-button',{attrs:{"variant":"secondary text-white spacing_1"},on:{"click":function($event){return _vm.downloadPatientVersion()}}},[_c('b-icon',{attrs:{"icon":"download"}}),_vm._v(" scarica pdf per il paziente ")],1)],1)],1)]:_vm._e()],2):_vm._e()])]):_c('div',[_c('h4',{staticClass:"fw-bolder"},[_vm._v("Operazione non consentita.")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"text-justify py-3"},[_vm._v(" Cliccando sul tasto \"scarica pdf per il paziente\" puoi scaricare il questionario appena compilato in versione stampabile, comprensivo di informativa (laddove presente) e di spazio dedicato per la firma. Potrai comunque scaricare il pdf per il paziente e il questionario in altri formati accendendo alla reportistica dedicata al questionario. "),_c('br'),_vm._v(" Per pianificare un follow up, vai alla scheda del paziente e clicca sul tasto \"+\" che trovi nella sezione \"prenotazioni\". ")])}]

export { render, staticRenderFns }