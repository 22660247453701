import Vue from "vue";
import VueRouter from "vue-router";
import MaintenanceMode from "@/views/MaintenanceMode.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import Home from "@/views/Home.vue";
import LoginPage from "@/views/LoginPage.vue";
import RegisterPage from "@/views/RegisterPage.vue";
import RecoverPassword from "@/views/RecoverPassword.vue";
import PasswordRecovery from "@/views/PasswordRecovery.vue";
import EmailVerification from "@/views/EmailVerification.vue";
import NumberVerification from '@/views/NumberVerification.vue';
import Profilo from "@/views/Profilo.vue";
import Disabled from "@/views/Disabled.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import Impostazioni from "@/views/Impostazioni.vue";
import GuidaCittadino from "@/views/cittadino/GuidaCittadino.vue";
import CittadinoHome from "@/views/cittadino/CittadinoHome.vue";
import NuovoOrdine from "@/views/cittadino/NuovoOrdine.vue";
import AgendaCittadino from "@/views/cittadino/AgendaCittadino.vue";
import CompanyHome from "@/views/operatore/CompanyHome.vue";
import GuidaOperatore from "@/views/operatore/GuidaOperatore.vue";
import HomeAttivita from "@/views/operatore/attivita/HomeAttivita.vue";
import HomeQuestionari from "@/views/operatore/attivita/HomeQuestionari.vue";
import AdminHomeAttivita from "@/views/admin/attivita/AdminHomeAttivita.vue";
import AdminHome from "@/views/admin/AdminHome.vue";
import AdminMain from "@/views/admin/AdminMain.vue";
import GestioneAziende from "@/views/admin/GestioneAziende.vue";
import LogSistema from "@/views/admin/LogSistema.vue";
import DettaglioAzienda from "@/views/admin/DettaglioAzienda.vue";
import GestioneServizi from "@/views/admin/GestioneServizi.vue";
import GestioneFacile from "@/views/admin/GestioneFacile.vue";
import GestioneTeleconsulto from "@/views/admin/GestioneTeleconsulto.vue";
import GestioneUtenti from "@/views/admin/GestioneUtenti.vue";
import CreaQuestionario from "@/views/admin/attivita/CreaQuestionario.vue";
import AggiornaQuestionario from "@/views/admin/attivita/AggiornaQuestionario.vue";
import QuestionariCompilati from "@/views/admin/attivita/QuestionariCompilati.vue";
import ReportCompilatiAbilitata from "@/views/admin/attivita/ReportCompilatiAbilitata.vue";
import AbilitateAttivita from "@/views/admin/attivita/AbilitateAttivita.vue";
import ReportQuestionariCompilati from "@/views/operatore/attivita/ReportQuestionariCompilati.vue";
import CreaNuovoQuestionario from "@/views/operatore/attivita/CreaNuovoQuestionario.vue";
import ModificaQuestionario from "@/views/operatore/attivita/ModificaQuestionario.vue";
import ModificaQuestionarioCompilato from "@/views/operatore/attivita/ModificaQuestionarioCompilato.vue";
import CompilaQuestionario from "@/views/operatore/attivita/CompilaQuestionario.vue";
import HomeTeleconsulto from "@/views/operatore/teleconsulto/HomeTeleconsulto.vue";
import PreConsulto from "@/views/operatore/teleconsulto/PreConsulto.vue";
import PreviewConsulto from "@/views/cittadino/PreviewConsulto.vue";
import GestioneConTe from "@/views/admin/GestioneConTe.vue";
import ReportIrst from "@/views/operatore/attivita/ReportIrst.vue";
import DatiApp from "@/views/admin/DatiApp.vue";
import HomePrenotazioni from "@/views/operatore/prenotazioni/HomePrenotazioni.vue";
import OperatoreMain from "@/views/operatore/OperatoreMain.vue";
import CalendarioPrenotazioni from "@/views/operatore/prenotazioni/CalendarioPrenotazioni.vue";
import FamiliariView from "@/views/cittadino/FamiliariView.vue";
import NuovoFamiliare from "@/views/cittadino/NuovoFamiliare.vue";
import CircoloCura from "@/views/cittadino/CircoloCura.vue";
import ProfiloSanitario from "@/views/cittadino/ProfiloSanitario.vue";
import NuovaTerapia from "@/views/cittadino/NuovaTerapia.vue";
import CittadinoMain from "@/views/cittadino/CittadinoMain.vue";
import HomePazienti from "@/views/operatore/pazienti/HomePazienti.vue";
import SchedaPaziente from "@/views/operatore/pazienti/SchedaPaziente.vue";
import NuovaTerapiaPaziente from "@/views/operatore/pazienti/NuovaTerapiaPaziente.vue";
import NuovaPrenotazionePaziente from "@/views/operatore/pazienti/NuovaPrenotazionePaziente.vue";
import HomeClienti from "@/views/operatore/clienti/HomeClienti.vue";
import SchedaCliente from "@/views/operatore/clienti/SchedaCliente.vue";
import NuovaTerapiaCliente from "@/views/operatore/clienti/NuovaTerapiaCliente.vue";
import NuovaPrenotazioneCliente from "@/views/operatore/clienti/NuovaPrenotazioneCliente.vue";
import CompanyAgenda from "@/views/operatore/agenda/CompanyAgenda.vue";
import NuovoAlertPaziente from "@/views/operatore/pazienti/NuovoAlertPaziente.vue";
import ModificaAlertPaziente from "@/views/operatore/pazienti/ModificaAlertPaziente.vue";
import ReplicaAlertPaziente from "@/views/operatore/pazienti/ReplicaAlertPaziente.vue";
import UserCompilaQuestionario from "@/views/cittadino/UserCompilaQuestionario.vue";
import MaterialeInformativo from "@/views/operatore/MaterialeInformativo.vue";
import HomePianiTerapeutici from "@/views/operatore/pianiTerapeutici/HomePianiTerapeutici.vue"
import DetailPianoTerapeutico from "@/views/operatore/pianiTerapeutici/DetailPianoTerapeutico.vue"
import PianoTerapeuticoCliente from "@/views/operatore/clienti/PianoTerapeuticoCliente.vue"
import PianoTerapeuticoUtente from "@/views/cittadino/PianoTerapeuticoUtente.vue"
import AdminPianiTerapeutici from "@/views/admin/pianiTerapeutici/AdminPianiTerapeutici.vue"
import PianoTerapeuticoDetail from "@/views/admin/pianiTerapeutici/PianoTerapeuticoDetail.vue"
import HomeStatisticheAttivita from "@/views/operatore/attivita/HomeStatisticheAttivita.vue";
import Help from "@/views/Help.vue"

Vue.use(VueRouter);
const routes = [
  // {
  //   path: '/*',
  //   name: 'manutenzione',
  //   component: MaintenanceMode
  // },
  // {
  //   path: '/login',
  //   name: 'manutenzione',
  //   component: MaintenanceMode
  // },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/register", name: "RegisterPage", component: RegisterPage },
  { path: "/profilo", name: "Profilo", component: Profilo },
  { path: "/impostazioni", name: "Impostazioni", component: Impostazioni },
  { path: "/disabled", name: "Disabled", component: Disabled },
  {
    path: "/password/reset",
    name: "RecoverPassword",
    component: RecoverPassword,
  },
  {
    path: "/password/recovery",
    name: "recupero password",
    component: PasswordRecovery,
  },
  {
    path: "/help",
    name: "Guida",
    component: Help
  },
  {
    path: "/email/verify/:status",
    name: "EmailVerification",
    component: EmailVerification,
  },
  {
    path: "/number/verify",
    name: "verifica numero",
    component: NumberVerification
  },
  {
    path: "/cittadino",
    component: CittadinoMain,
       children: [
        { path: "home", name: "CittadinoHome", component: CittadinoHome },
        { path: "help", name: "FAQ", component: GuidaCittadino },
        { path: "prenotazioni/:type?", name: "Nuovo Ordine", component: NuovoOrdine },
        { path: "familiari", name: "familiari", component: FamiliariView },
        { path: "familiari/nuovo", name: "Nuovo assistito", component: NuovoFamiliare },
        { path: "agenda", name: "agenda", component: AgendaCittadino },
        { path: "teleconsulto/:reserv?", name: "il tuo consulto", component: PreviewConsulto },
        { path: "circolocura", name: "circolo di cura", component: CircoloCura },
        { path: "profilosanitario", name: "profilo sanitario", component: ProfiloSanitario },
        { path: "profilosanitario/terapia", name: "nuova terapia", component: NuovaTerapia },
        { path: "profilosanitario/pt/:hash", name: "il tuo piano terapeutico", component: PianoTerapeuticoUtente },
        { path: "questionario/compila/:quest?", name: "completa questionario", component: UserCompilaQuestionario },
      ],
  }, 
  {
    path: "/operatore",
    component: OperatoreMain,
    children: [
      { path: "home", name: "CompanyHome", component: CompanyHome },
      { path: "prenotazioni", name: "prenotazioni", component: HomePrenotazioni },
      { path: "teleconsulto", name: "teleconsulto", component: HomeTeleconsulto },
      { path: "attivita", name: "attivita", component: HomeAttivita },
      { path: "agenda", name: "agenda appuntamenti", component: CompanyAgenda },
      { path: "help", name: "domande frequenti", component: GuidaOperatore },
      { path: "prof2", name: "progetto prof2", component: ReportIrst },
      { path: "pazienti", name: "pazienti", component: HomePazienti },
      { path: "clienti", name: "Pazienti", component: HomeClienti },
      { path: "scaricabili", name: "materiale informativo", component: MaterialeInformativo },
      { path: "pt", name: "piani terapeutici", component: HomePianiTerapeutici },
      { path: "pt/scheda/:hash", name: "piano terapeutico", component: DetailPianoTerapeutico },
      { path: "pazienti/scheda/:hash", name: "impostazione alerts", component: SchedaPaziente },
      { path: "clienti/scheda/:hash", name: "scheda Paziente", component: SchedaCliente },
      { path: "pazienti/terapia", name: "nuova terapia paziente", component: NuovaTerapiaPaziente },
      { path: "clienti/terapia", name: "nuova terapia Paziente", component: NuovaTerapiaCliente },
      { path: "pazienti/servizi/:hash", name: "prenota servizio", component: NuovaPrenotazionePaziente },
      { path: "clienti/servizi/:hash", name: "nuova prenotazione servizio", component: NuovaPrenotazioneCliente },
      { path: "clienti/pt/scheda/:hash", name: "dettaglio piano terapeutico", component: PianoTerapeuticoCliente },
      { path: "pazienti/alerts/:hash", name: "nuova notifica paziente", component: NuovoAlertPaziente },
      { path: "pazienti/alerts/edit/:hash", name: "modifica piano notifiche", component: ModificaAlertPaziente },
      { path: "pazienti/alerts/copy/:hash", name: "replica piano notifiche", component: ReplicaAlertPaziente },
      { path: "teleconsulto/videoconsulto/:reserv?", name: "videoconsulto", component: PreConsulto },
      { path: "teleconsulto/calendario", name: "telecalendario", component: CalendarioPrenotazioni },
      { path: "attivita/statistiche", name: "statistiche", component: HomeStatisticheAttivita },
      { path: "attivita/questionari/:quest?", name: "questionari", component: HomeQuestionari },
      { path: "attivita/questionario/nuovo", name: "crea questionario", component: CreaNuovoQuestionario },
      { path: "attivita/questionario/modifica/:quest", name: "modifica questionario", component: ModificaQuestionario },
      { path: "attivita/questionario/compilato/modifica/:quest", name: "modifica questionario compilato", component: ModificaQuestionarioCompilato },
      { path: "prenotazioni/calendario", name: "calendario prenotazioni", component: CalendarioPrenotazioni },
      { path: "questionario/compila/:quest?", name: "somministra questionario", component: CompilaQuestionario },
      { path: "attivita/questionario/report/:quest", name: "report per questionario", component: ReportQuestionariCompilati },
    ]
  },
  {
    path: "/admin",
    component: AdminMain,
    children:[
      { path: "home", name: "AdminHome", component: AdminHome },
      { path: "aziende", name: "gestione aziende", component: GestioneAziende },
      { path: "aziende/dettaglio/:company", name: "dettaglio azienda", component: DettaglioAzienda },
      { path: "applicazione", name: "dati applicazione", component: DatiApp },
      { path: "syslog", name: "log di sistema", component: LogSistema },
      { path: "servizi", name: "gestione servizi", component: GestioneServizi },
      { path: "prenotazioni", name: "servizio facile", component: GestioneFacile },
      { path: "teleconsulto", name: "servizio di teleconsulto", component: GestioneTeleconsulto },
      { path: "ricercautente", name: "cerca utente", component: GestioneUtenti },
      { path: "piani", name: "piani asl", component: AdminPianiTerapeutici },
      { path: "piani/scheda/:hash", name: "dettaglio pt asl", component: PianoTerapeuticoDetail },
      { path: "attivita/questionari/nuovo", name: "nuovo questionario", component: CreaQuestionario },
      { path: "attivita/questionari/modifica/:quest", name: "aggiorna questionario", component: AggiornaQuestionario },
      { path: "attivita/questionario/report/:quest", name: "report questionario", component: QuestionariCompilati },
      { path: "attivita", name: "attività e questionari", component: AdminHomeAttivita },
      { path: "attivita/abilitate/:activity", name: "dettagli attività", component: AbilitateAttivita },
      { path: "attivita/abilitate/report/:activity/:company", name: "report farmacia", component: ReportCompilatiAbilitata },
    ],
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  { path: "/privacypolicy", name: "Privacy Policy", component: PrivacyPolicy },
  {
    path: '/*',
    name: 'pagina non trovata',
    component: NotFoundPage
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register", "/password/reset","/password/recovery","/disabled","/email/verify/already","/email/verify/success", "/privacypolicy"];
  const authRequired = !publicPages.includes(to.path);
  const intended = to.path;
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login?redirect="+intended);
  }

  if (!authRequired && loggedIn) {
    if(to.query.access_token) {
      return next("/?access_token="+to.query.access_token);
    }
    return next("/");
  }

  next();
});
router.afterEach((to, from) => {
  const mainPages = [
    "/cittadino/home",
    "/operatore/home",
    "/admin/home",
    "/impostazioni",
    "/profilo",
    "/orari",
  ];
  if (mainPages.includes(to.path)) {
    router.app.$root.$root.menuLeft = false;
  } else {
    router.app.$root.$root.menuLeft = true;
  }
  window.scrollTo(0,0)
});

export default router;
