<template>
  <div
    class="py-3"
    v-if="user.permessi.includes('gestire attività e questionari')"
  >
    <div v-if="loading" class="col-12 text-center">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between">
        <router-link
          class="text-primary no__hover underlined"
          :to="{ name: 'attivita', params: { section: 'questionari' } }"
        >
          <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
          Questionari
        </router-link>

        <router-link
          v-if="selectedUser"
          class="text-secondary no__hover underlined"
          :to="{
            name: 'scheda paziente',
            params: { hash: selectedUser.hashid }
          }"
          target="_blank"
        >
          Scheda Paziente
          <b-icon icon="arrow-right-circle-fill" variant="secondary"></b-icon>
        </router-link>
      </div>
      <div v-if="survey && !complete" class="py-3">
        <h4 class="text-left pb-1">Modifica {{ survey.survey_name }}</h4>
        <EditQuestionarioCompilato
          :filledSurvey="survey"
          :originalSurvey="originalSurvey"
          :patient="surveyPatient"
          v-on:filled-form="submitForm($event)"
        />
      </div>
      <div v-else-if="complete">
        <h2 class="fw-bolder">Operazione completata.</h2>
        <h5 class="text-center mt-3" v-if="editedSurvey && editedSurvey.score !== undefined">
          Punteggio totalizzato: {{ editedSurvey.score }}
        </h5>
        <div class="text-left" v-if="editedSurvey.reference">
          <template v-if="survey.type !== 0">
            <h5 class="text-ptim-grad-1">Interpretazione punteggio:</h5>
            <template v-for="(reference, ind) in editedSurvey.reference">
              <h6 class="fw-bolder">
                Totale
                <span v-if="reference.min_ref === reference.max_ref">
                  {{ reference.max_ref * 1 }}</span
                >
                <span v-else>
                  {{ reference.min_ref * 1 }} -
                  {{ reference.max_ref * 1 }}
                </span>
                <!-- <span class="font-italic" v-if="reference.description"
                >: {{ reference.description }}</span
              > -->
                <template v-if="reference.description">
                  <span
                    class="font-italic"
                    v-html="'<span>' + reference.description + '</span>'"
                  ></span>
                </template>
              </h6>
            </template>
          </template>
          <template v-else>
            <h5 class="text-ptim-grad-1 mt-4 pt-4">
              Interpretazione punteggio:
            </h5>
            <template v-for="(reference, ind) in editedSurvey.reference">
              <h6
                class="fw-bolder"
                v-if="
                  editedSurvey.score >= reference.min_ref &&
                  editedSurvey.score <= reference.max_ref
                "
              >
                <!-- <span class="font-italic" v-if="reference.description"
                >: {{ reference.description }}</span
              > -->
                <template v-if="reference.description">
                  <span
                    class="font-italic"
                    v-html="'<span>' + reference.description + '</span>'"
                  ></span>
                </template>
              </h6>
            </template>
          </template>
        </div>
        <div class="text-left" v-if="editedSurvey.prevs">
          <h5 class="text-ptim-grad-1">Storico punteggi:</h5>
          <template v-for="(prevScore, ind) in editedSurvey.prevs">
            <h6 class="fw-bolder">
              Punteggio totalizzato il
              {{ prevScore.created_at | moment("DD/MM/YYYY") }}:
              {{ prevScore.value * 1 }}

              <!-- <span
              class="font-italic"
              v-if="
                prevScore.score_reference &&
                prevScore.score_reference.description
              "
              >{{ prevScore.score_reference.description }}</span
            > -->

              <!-- <template
              v-if="
                prevScore.score_reference &&
                prevScore.score_reference.description
              "
            >
              <span
                class="font-italic"
                v-html="
                  '<span>' + prevScore.score_reference.description + '</span>'
                "
              ></span>
            </template> -->
            </h6>
          </template>
        </div>
        <template v-if="editedSurvey && editedSurvey.survey.type === 2">
          <h6 class="text-justify py-3">
            Cliccando sul tasto "scarica pdf per il paziente" puoi scaricare il
            questionario appena compilato in versione stampabile, comprensivo di
            informativa (laddove presente) e di spazio dedicato per la firma.
            Potrai comunque scaricare il pdf per il paziente e il questionario
            in altri formati accendendo alla reportistica dedicata al
            questionario.
            <br />
            Per pianificare un follow up, vai alla scheda del paziente e clicca
            sul tasto "+" che trovi nella sezione "prenotazioni".
          </h6>
          <b-row class="mt-3">
            <b-col>
              <b-button
                variant="secondary text-white  spacing_1"
                @click="
                  $router.push({
                    name: 'attivita',
                    params: {
                      section: 'questionario',
                      action: 'report',
                      quest: editedSurvey.survey.hashid
                    }
                  })
                "
                >Torna ai report</b-button
              >
            </b-col>
            <b-col>
              <b-button
                variant="secondary text-white spacing_1"
                @click="downloadPatientVersion()"
              >
                <b-icon icon="download"></b-icon>
                scarica pdf per il paziente
              </b-button>
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
  </div>
  <div v-else>
    <h4 class="fw-bolder">Operazione non consentita.</h4>
  </div>
</template>
<script>
import { supportService, cittadinoService } from "@/_services"
import { mapState, mapActions } from "vuex"
import EditQuestionarioCompilato from "@/components/attivita/EditQuestionarioCompilato.vue"
export default {
  name: "ModificaQuestionarioCompilato",
  components: { EditQuestionarioCompilato },
  computed: {
    userIsVerified() {
      if (this.selectedUser) {
        if (!this.selectedUser.number_verified_at) {
          return false
        }
        if (!this.selectedUser.email) {
          return this.selectedUser.second_number_verified_at != null
        }
        return true
      }
      return false
    },
    canEditAnagrafica() {
      if (this.selectedUser && this.selectedUser.created_by_me) {
        if (
          this.selectedUser.email_verified_at ||
          this.selectedUser.number_verified_at
        ) {
          return false
        }
        return this.selectedUser.all_consenses === false
      }
      return false
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  data() {
    return {
      hash: this.$route.params.quest,
      survey: null,
      complete: false,
      needToSelectUser: true,
      needToSelectPseudo: true,
      selectedUser: null,
      loading: false,
      editedSurvey: null,
      originalSurvey: null,
      surveyPatient: null,
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      const self = this
      supportService
        .getFilledSurveyByIdWithInfo(this.hash)
        .then(function (res) {
          // console.log("GOTTEN SURVEY", res.data.data)
          self.originalSurvey = res.data.data.survey
          //self.survey is everything after having removed the survey key
          self.survey = res.data.data.filledSurvey
          self.surveyPatient = res.data.data.user
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le attività")
        })
      //
    },
    goToScheda() {
      this.$router.push({
        name: "scheda paziente",
        params: { hash: this.selectedUser.hashid }
      })
    },
    submitForm(form) {
      this.loading = true
      const formData = {
        response: JSON.stringify(form.template)
      }
      const self = this
      supportService
        .editFilledSurvey(this.survey.hashid, formData)
        .then(function (resp) {
          self.loading = false
          self.complete = true
          self.editedSurvey = resp.data.data
          window.scrollTo(0, 0)
          self.successAlert("Questionario modificato correttamente.")
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile modificare il questionario")
          self.loading = false
        })
    },
    downloadPatientVersion() {
      const self = this
      supportService
        .downloadFilledSurveyPatientVersion(this.survey.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "patient_" + self.survey.survey_name + ".pdf"
          link.click()
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare i report")
        })
    },
    fetchUser(hashid = null) {
      var userHashid = this.$route.query.user
      if (hashid !== null) {
        var userHashid = hashid
      }
      const self = this
      cittadinoService
        .getUserById(userHashid)
        .then(function (res) {
          self.selectedUser = res.data.data
          self.loading = false
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err)
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare le informazioni.")
        })
    }
  },
  created() {
    if (this.$route.params.quest) {
      this.hash = this.$route.params.quest
      this.fetchData()
    }
    if (this.$route.query.user) {
      this.loading = true
      this.fetchUser()
    }

    // this.fetchData();
  }
}
</script>
