<template>
  <div class="text-left px-md-4">
    <h4 class="text-prim-grad-1 text-left pb-2 mb-4">Gestione Utenti</h4>
    <div class="px-4 py-2">
      <div class="d-flex align-items-center justify-content-start">
        <div class="d-flex" style="flex-grow: 1">
          <b-form-radio
            v-model="searchType"
            name="searchTypeRadio"
            value="email"
            class="pr-4"
            >ricerca per email</b-form-radio
          >
          <b-form-radio
            v-model="searchType"
            name="searchTypeRadio"
            value="cf"
            class="pr-4"
            >ricerca per codice fiscale</b-form-radio
          >
          <b-form-radio
            class="pr-4"
            v-model="searchType"
            name="searchTypeRadio"
            value="surname"
            >ricerca per cognome</b-form-radio
          >
        </div>
      </div>
      <b-row class="mt-4 pb-2 d-flex">
        <b-col class="col-12 col-md-6 px-0">
          <b-form-group
            v-if="searchType === 'email'"
            label-for="email"
            class="text-left col-12 mr-auto px-0 m-0 fs-6 text-secondary"
          >
            <b-form-input
              class="shadow_6 border-secondary"
              name="email"
              type="email"
              required
              placeholder="Indirizzo email"
              v-model="params.email"
              :state="params.email == null ? null : validEmail"
              @input="nullEmail"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="searchType === 'cf'"
            label-for="cf"
            class="text-left col-12 mr-auto px-0 m-0 fs-6 text-secondary"
          >
            <b-form-input
              class="shadow_6 border-secondary"
              name="cf"
              required
              placeholder="Codice Fiscale"
              maxlength="16"
              minlength="16"
              v-model="params.cf"
              :state="params.cf == null ? null : validCf"
              @input="
                (v) => {
                  params.cf = v.toUpperCase()
                  this.nullCf()
                }
              "
            ></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="searchType === 'surname'"
            label-for="surname"
            class="text-left col-12 mr-auto px-0 m-0 fs-6 text-secondary"
          >
            <b-form-input
              class="shadow_6 border-secondary"
              name="surname"
              placeholder="Cognome"
              required
              v-model="params.surname"
              :state="params.surname == null ? null : true"
              @input="nullSurname"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-1 pl-0 d-flex align-self-end">
          <b-button @click="searchUser()" class="mt-2 mt-md-0 ml-0 ml-md-2"
            >Cerca</b-button
          >
        </b-col>
      </b-row>
    </div>

    <div class="mt-4 general_border" v-if="!notFound">
      <b-table
        hover
        borderless
        responsive
        table-variant="white text-info text-uppercase text-left row__font"
        :fields="fields"
        :items="searchResult"
        :small="$root.isMobile"
        thead-class=" border-medium-grey text-left"
        tbody-class=""
        ref="usersearchtble"
        tbody-tr-class="text-black border-bottom border-medium-grey"
        empty-text="Nessun utente trovato"
        show-empty
        :busy="searching"
      >
        <template #cell(azioni)="row">
          <b-row class="flex-wrap mt-n2">
            <b-button
              size="sm"
              variant="info info_gradient rounded-pill fw-bolder px-3 fs-6 m-1 mt-2"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? "Chiudi" : "Dettagli" }}
            </b-button>
          </b-row>
        </template>
        <template #row-details="row">
          <p class="d-flex justify-content-end pt-1 mb-n1">
            <b-button
              size="sm"
              variant="info info_gradient rounded-pill fw-bolder px-3 fs-6 m-1"
              @click="
                $bvModal.show('pazienteAnagraficaEditor')
                onRowSelected(row.item)
              "
            >
              <b-icon icon="pencil-fill" class="me-2"></b-icon>
              Modifica Anagrafica</b-button
            >
          </p>
          <b-row
            align-v="stretch"
            cols="2"
            cols-lg="3"
            cols-xl="4"
            class="mt-2 bg-light border border-font-light-grey py-2"
          >
            <b-col>
              <h6 class="fs-6">Username:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.username }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">Data ultimo login:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.last_login | moment("DD/MM/YYYY") }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">Email verificata:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                <span v-if="row.item.email_verified_at">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  {{ row.item.email_verified_at | moment("DD/MM/YYYY") }}</span
                >
                <span v-else
                  ><b-icon icon="x-circle-fill" variant="danger"></b-icon>
                  No</span
                >
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">Telefono verificato:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                <span v-if="row.item.number_verified_at">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  {{ row.item.number_verified_at | moment("DD/MM/YYYY") }}</span
                >
                <span v-else
                  ><b-icon icon="x-circle-fill" variant="danger"></b-icon>
                  No</span
                >
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">Telefono di riserva:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.anagrafica.tel_number_secondary }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">Telefono di riserva verificato:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                <span v-if="row.item.second_number_verified_at">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  {{
                    row.item.second_number_verified_at | moment("DD/MM/YYYY")
                  }}</span
                >
                <span v-else
                  ><b-icon icon="x-circle-fill" variant="danger"></b-icon>
                  No</span
                >
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">Password:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                <span v-if="row.item.temporary_password">
                  <b-icon icon="x-circle-fill" variant="danger"></b-icon>
                  temporanea
                </span>
                <span v-else>
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  personalizzata
                </span>
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">T&C e PP:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                <span v-if="!row.item.all_consenses">
                  <b-icon icon="x-circle-fill" variant="danger"></b-icon>
                  non accettati
                </span>
                <span v-else>
                  <b-icon icon="check-circle-fill" variant="success"></b-icon>
                  accettati
                </span>
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">Tel casa:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.anagrafica.home_number }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">regione:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.anagrafica.regione }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">provincia:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.anagrafica.provincia }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">comune:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.anagrafica.comune }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">indirizzo:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.anagrafica.address }} {{ row.item.anagrafica.cap }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">data nascita:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.anagrafica.birthday | moment("DD/MM/YYYY") }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">N° tessera sanitaria:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{
                  row.item.anagrafica.ts_number
                    ? row.item.anagrafica.ts_number
                    : "-"
                }}
              </h6>
            </b-col>
            <b-col>
              <h6 class="fs-6">documento:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.id_type }} - {{ row.item.id_number }}
              </h6>
            </b-col>
            <b-col v-if="row.item.arruolato && row.item.arruolato_by_company">
              <h6 class="fs-6">Arruolato da:</h6>
              <h6
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.arruolato_by_company.denominazione }} -
                {{ row.item.arruolato_by_company.codice_tracciabilita }}
                <br />
                <span class="text-capitalize">
                  {{ row.item.arruolato_by_company.comune }},
                </span>
                <span
                  class="text-capitalize"
                  v-if="
                    row.item.arruolato_by_company.frazione &&
                    row.item.arruolato_by_company.frazione !==
                      row.item.arruolato_by_company.comune
                  "
                >
                  {{ row.item.arruolato_by_company.frazione }},
                </span>

                {{ row.item.arruolato_by_company.indirizzo }}
                {{ row.item.arruolato_by_company.cap }}
              </h6>
            </b-col>

            <b-col v-if="row.item.type === 1 && row.item.company">
              <h6 class="fs-6">Operatore di:</h6>
              <h6
                v-if="Array.isArray(row.item.company)"
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.company[0].denominazione }} -
                {{ row.item.company[0].codice_tracciabilita }}
                <br />
                <span class="text-capitalize">
                  {{ row.item.company[0].comune }},
                </span>
                <span
                  class="text-capitalize"
                  v-if="
                    row.item.company[0].frazione &&
                    row.item.company[0].frazione !== row.item.company[0].comune
                  "
                >
                  {{ row.item.company[0].frazione }},
                </span>

                {{ row.item.company[0].indirizzo }}
                {{ row.item.company[0].cap }}
              </h6>
              <h6
                v-else
                class="fs-6 text-lowercase border-bottom border-font-light-grey pb-2"
              >
                {{ row.item.company.denominazione }} -
                {{ row.item.company.codice_tracciabilita }}
                <br />
                <span class="text-capitalize">
                  {{ row.item.company.comune }},
                </span>
                <span
                  class="text-capitalize"
                  v-if="
                    row.item.company.frazione &&
                    row.item.company.frazione !== row.item.company.comune
                  "
                >
                  {{ row.item.company.frazione }},
                </span>

                {{ row.item.company.indirizzo }}
                {{ row.item.company.cap }}
              </h6>
            </b-col>
          </b-row>
        </template>
      </b-table>
      <b-modal
        id="pazienteAnagraficaEditor"
        title="Modifica Anagrafica"
        hide-footer
        size="lg"
      >
        <AdminPazienteAnagraficaEditor
          :user="selectedUser"
          @edit-done="
            $bvModal.hide('pazienteAnagraficaEditor')
            searchUser()
          "
          @cancel-operation="$bvModal.hide('pazienteAnagraficaEditor')"
        />
      </b-modal>
    </div>
    <p v-else-if="!notYetSearched" class="mt-4 text-center">
      Nessun risultato trovato con i criteri di ricerca selezionati.
    </p>
  </div>
</template>
<script>
import { utilityService } from "@/_services"
import { mapState, mapActions } from "vuex"
import AdminPazienteAnagraficaEditor from "@/components/admin/AdminPazienteAnagraficaEditor.vue"
export default {
  name: "GestioneUtenti",
  components: { AdminPazienteAnagraficaEditor },
  data() {
    return {
      fields: [
        { key: "i_am", label: "Tipo utente" },
        { key: "anagrafica.name", label: "Nome" },
        { key: "anagrafica.surname", label: "Cognome" },
        { key: "anagrafica.cf", label: "CF" },
        { key: "email", label: "Email" },
        // { key: "email_verified_at", label: "Email verificata" },
        { key: "anagrafica.tel_number", label: "Telefono" },
        // { key: "number_verified_at", label: "Tel verificato" },
        { key: "azioni", label: "" }
      ],
      searchType: "email",
      params: {
        cf: null,
        email: null,
        surname: null
      },
      searchResult: null,
      notFound: true,
      notYetSearched: true,
      selectedUser: null,
      searching: false
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    validCf() {
      var validRegex = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/
      return this.params.cf.match(validRegex) ? true : false
    },
    validEmail() {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      return this.params.email.match(validRegex) ? true : false
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    alertInSviluppo() {
      this.infoAlert("Funzionalità in sviluppo")
    },
    searchUser() {
      this.searching = true
      this.notFound = false
      this.searchResult = null
      const self = this
      utilityService
        .searchUser(this.params)
        .then(function (res) {
          self.searchResult = Array.isArray(res.data.data)
            ? res.data.data
            : [res.data.data]
          self.notYetSearched = false
          self.searching = false
        })
        .catch(function (err) {
          self.notFound = true
          self.notYetSearched = false
          self.searching = false
        })
    },
    nullCf() {
      if (this.params.cf.length == 0) {
        this.params.cf = null
      } else {
        this.params.surname = null
        this.params.email = null
      }
    },
    nullEmail() {
      if (this.params.email.length == 0) {
        this.params.email = null
      } else {
        this.params.surname = null
        this.params.cf = null
      }
    },
    nullSurname() {
      if (this.params.surname.length == 0) {
        this.params.surname = null
      } else {
        this.params.cf = null
        this.params.email = null
      }
    },
    onRowSelected(items) {
      this.selectedUser = items
    }
  },
  mounted() {}
}
</script>
<style>
.hide__me {
  visibility: hidden !important;
}
</style>
