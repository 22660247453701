<template>
  <b-row cols="1" class="my-1">
    <p class="text-justify fw-bolder mb-0 text-medium-grey">
      Puoi effettuare un ordine ricercando i prodotti che ti interessano
      oppure inserendo una ricetta. I farmaci inclusi nella ricetta non devono
      essere inseriti manualmente.

    </p>
    <!-- <div class="border general__border border-grey"> -->
    <b-col class="px-0 px-md-2">
      <p class="text-left fw-bolder mb-0 big__font">
        <b-icon icon="cart-fill" class="mr-1"></b-icon>Cerca prodotti

      </p>
      <p class="mt-2 text-left fw-bolder">
        Cerca i prodotti che ti servono digitando qui sotto almeno 3 caratteri:
      </p>
      <b-input-group label="Cerca prodotto" class="align-items-center mx-auto shadow_6">
        <b-form-input class="" type="search" placeholder="Cerca prodotto.." v-model="prodSearch.q"
          v-debounce:500ms="searchProds" :debounce-events="'keyup'">
        </b-form-input>

        <b-input-group-append>
          <b-spinner v-if="isSearching" class="mr-2" variant="primary" label="Loading..."></b-spinner>
          <span v-else class="px-1">
            <b-icon icon="search"></b-icon>
          </span>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col v-if="showNotFound">
      <span class="text-left text-danger mt-2">
        Nessun prodotto trovato
        {{ prodSearch.q.length >= 3 ? "per " + prodSearch.q : "" }}</span>
    </b-col>
    <b-col v-if="products" class="pt-1 px-0 px-md-2">
      <b-form-group label="Seleziona uno o più prodotti" label-for="prod"
        class="text-left fs-6 text-secondary mt-2 px-0">
        <b-form-tags input-id="tags-basic" v-model="selectedProducts" placeholder="  " add-on-change no-outer-focus
          class="px-0">
          <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
            <b-form-select v-bind="inputAttrs" v-on="inputHandlers" v-model="selectedProduct" @change="addProds"
              class="bg-prim-grad-1 text-white">
              <template #first>
                <!-- This is required to prevent bugs with Safari -->
                <option disabled value="">Seleziona prodotti...</option>
              </template>
              <b-form-select-option :value="null" disabled>Seleziona prodotto...</b-form-select-option>
              <b-form-select-option v-for="(prod, ind) in products" :key="ind" :value="prod">{{
                      user.type === 1
                        ? prod.Nome + " - AIC " + prod.Aic
                        : prod.Nome
                    }}
              </b-form-select-option>
            </b-form-select>
            <template v-if="tags.length > 0" class="my-1">
              <b-row v-for="(tag, kk) in tags" :key="kk"
                class="text-left justify-items-start bg-info_shadow border-bottom border-primary mt-1" align-v="end">
                <b-col cols="4" md="2">
                  <b-form-group label="Quantità:">
                    <b-form-input class="text-left shadow_6" type="number" :min="1" :step="1"
                      v-model="selectedProductsDetails[kk].quantity">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4" md="2">
                  <b-form-group class="align-items-center" label="">
                    <b-form-checkbox v-model="selectedProductsDetails[kk].generic" :name="'generico'+kk" :value="true"
                      :unchecked-value="false">
                      Generico
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Prodotto:">
                    <h5 class="fw-bolder text-prim-grad-1">{{ JSON.parse(tag).Nome }}</h5>
                  </b-form-group>
                </b-col>
                <b-col cols="2" md="1" class="align-self-start text-right ml-auto mr-0 mt-3">
                  <b-button size="sm" variant="prim-grad-1" class="mb-2" @click="handleRemove(removeTag,tag)">
                    <b-icon icon="x"></b-icon>
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <!-- <ul v-if="tags.length > 0" class="list-inline d-inline-block my-4">
              <li v-for="(tag, kk) in tags" :key="kk" class="list-inline-item">
                <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled"
                  variant="primary py-2 px-1 fw-bolder my-1">{{ JSON.parse(tag).Nome }}</b-form-tag>
              </li>
            </ul> -->
          </template>
        </b-form-tags>
      </b-form-group>
    </b-col>
    <b-col>
      <p class="mt-4 text-left mb-0 point" :class="showManualAdd ? null : 'collapsed'"
        :aria-expanded="showManualAdd ? 'true' : 'false'" aria-controls="collapse-prodadd"
        @click="showManualAdd = !showManualAdd">
        <b-icon icon="question-square" class="mr-1"></b-icon><u>Non trovo il prodotto che cerco</u>
      </p>
      <b-collapse id="collapse-prodadd" v-model="showManualAdd" class="mt-2">
        <b-card class="text-left">
          <b-row align-v="center">
            <b-col cols="9">
              <b-form-group label="Nome prodotto*:">
                <b-form-input class="text-left shadow_6" type="text" placeholder="Inserisci nome prodotto..."
                  v-model="customProduct.Nome"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button variant="primary" size="sm" class="px-3 py-2 mt-4" @click="addManualProd">Aggiungi</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-collapse>
    </b-col>
    <!-- </div> -->
    <b-col class="text-left px-0 px-md-2">
      <p class="mt-4 text-left fw-bolder mb-0 big__font">
        <b-icon icon="journal-text" class="mr-1"></b-icon>Hai una ricetta?
      </p>
      <p class="text-justify fw-bolder mb-0 text-medium-grey">
        Se vuoi effettuare un ordine per una ricetta medica o veterinaria
        compila uno dei campi sottostanti. Non è necessario inserire manualmente
        i prodotti previsti dalla ricetta.
      </p>
      <b-row cols="1" cols-md="2">
        <b-col class="px-0 px-md-2">
          <p class="mt-2 text-left fw-bolder">Inserisci il codice qui sotto</p>
          <b-form-group label="Inserisci codice NRE ricetta:" description="*15 caratteri.">
            <b-form-input class="text-left shadow_6" type="text" maxlength="15" placeholder="Inserisci NRE ricetta..."
              v-model="productOrder.nre" :state="
                productOrder.nre ? productOrder.nre.length === 15 : undefined
              "></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="px-0 px-md-2">
          <p class="mt-2 text-left fw-bolder">
            Se veterinaria, inserisci il codice qui sotto
          </p>
          <b-form-group label="Inserisci codice REV ricetta:" description="*13 caratteri. Non inlcudere il pin.">
            <b-form-input class="text-left shadow_6" type="text" placeholder="Inserisci REV ricetta..." maxlength="13"
              v-model="productOrder.rev" :state="
                productOrder.rev ? productOrder.rev.length === 13 : undefined
              "></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <p class="mt-2 text-left text-justify" v-if="!productOrder.resource_id && !gotNre">
        <strong>
          In alternativa, puoi caricare la ricetta in formato PDF</strong>
        <br />
        Carica la ricetta in formato pdf: il sistema leggerà il codice NRE dal
        file e lo inserirà nell'apposito campo. I file caricati qui non vengono
        salvati nel sistema. La lettura del codice è valida solo per le ricette
        NRE, per le ricette veterinarie si prega di inserire il codice
        manualmente nell'apposito campo.
      </p>
      <UploadComponent :encr="true" :readnre="true" v-on:upload-result="handleUpload($event)" />
    </b-col>
    <b-col class="pb-3 px-0 px-md-2" v-if="!productOrder.resource_id && !gotNre">
      <p class="mt-2 text-left fw-bolder">
        Oppure Scannerizzare il codice ricetta NRE tramite la videocamera
      </p>
      <BarcodeComponent v-on:set-nrecode="setNreCode($event)" />
    </b-col>
    <template v-if="!hasSaved">
      <b-col v-if="selectedProducts.length > 0 || productOrder.resource_id" class="mt-2">
        <b-button variant="info info_gradient fw-bolder" @click="confirmProducts">Salva ordine</b-button>
      </b-col>
      <b-col class="mt-2" v-else-if="
        (productOrder.nre && productOrder.nre.length >= 15) ||
        (productOrder.rev && productOrder.rev.length >= 13)
      ">
        <b-button variant="info info_gradient fw-bolder" @click="confirmProducts" :disabled="
          (productOrder.nre && productOrder.nre.length !== 15) ||
          (productOrder.rev && productOrder.rev.length !== 13)
        ">Salva ordine</b-button>
      </b-col>
    </template>
  </b-row>
</template>
<script>
import { utilityService } from "@/_services";
import UploadComponent from "@/components/utilities/UploadComponent.vue";
import BarcodeComponent from "@/components/utilities/BarcodeComponent.vue";
import { mapState } from "vuex";
export default {
  name: "ProductSelection",
  props: ["preselected"],
  components: {
    UploadComponent,
    BarcodeComponent,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      prodSearch: { q: null },
      products: null,
      productOrder: {
        nre: null,
        rev: null,
        resource_id: null,
        products: null,
      },
      showNotFound: false,
      selectedProduct: null,
      selectedProducts: [],
      selectedProductsDetails: [],
      gotNre: false,
      fileOrder: null,
      isSearching: false,
      hasSaved: false,
      showManualAdd: false,
      customProduct: {
        Aic: null,
        Nome: null,
        Tipo: null
      }
    };
  },
  methods: {
    emitProduct() {
      this.$emit("selected-product", this.productOrder);
    },
    handleRemove(doRemove, element) {
      doRemove(element);
      var toRemove = JSON.parse(element)
      var fullList = this.selectedProductsDetails.filter(function (el) {
        return el.Nome != toRemove.Nome
      })
      this.selectedProductsDetails = fullList
    },
    searchProds() {
      // //console.log(this.prodSearch);
      // //console.log("sel prod len", this.selectedProducts.length);
      if (this.prodSearch.q && this.prodSearch.q.length >= 3) {
        this.isSearching = true;
        this.showNotFound = false;
        var self = this;
        const searchMethod =
          this.user.type == 1
            ? utilityService.productsAll
            : utilityService.products
        searchMethod(this.prodSearch)
          .then(function (response) {
            //console.log(response);
            self.products = response.data;
            if (!(response.data instanceof Array)) {
              self.products = [response.data];
            }
            self.isSearching = false;
          })
          .catch(function (error) {
            console.log(error);
            self.showNotFound = true;
            self.isSearching = false;
          });
      } else if (this.selectedProducts.length === 0) {
        this.products = null;
      }
    },
    addProds(e) {
      console.log(e)
      var newProduct = e;
      newProduct.quantity = 1;
      newProduct.generic = false;
      this.selectedProductsDetails.push(newProduct)
      this.selectedProducts.push(newProduct);
      // this.selectedProducts.push(e);
    },
    addManualProd() {
      // this.selectedProduct = this.customProduct
      console.log("this.selectedProduct", this.selectedProduct)
      this.addProds(this.customProduct)
      this.customProduct = {
        Aic: null,
        Nome: null,
        Tipo: null
      }
      this.products = []
    },
    confirmProducts() {
      // var prods = [];
      // this.selectedProducts.map(function (el) {
      //   //console.log(el);
      //   prods.push(JSON.parse(el));
      // });
      // this.productOrder.products = prods;
      this.productOrder.products = this.selectedProductsDetails;
      this.$emit("selected-product", this.productOrder);
      this.hasSaved = true
    },
    handleUpload(data) {
      this.productOrder.resource_id = data.hashid;
      this.fileOrder = data;
      if (data.nre) {
        this.productOrder.nre = data.nre;
        this.gotNre = true;
      }
      this.$emit("uploaded-order", this.fileOrder);
    },
    setNreCode(codice) {
      if (codice) {
        this.productOrder.nre = codice;
        this.gotNre = true;
      }
    },
  },
  created() {
    // //console.log("preselected, created", this.preselected);
    this.productOrder = this.preselected;
  },
  mounted() {
    if (this.productOrder.products && this.productOrder.products.length > 0) {
      this.products = this.productOrder.products;
      this.selectedProducts = this.productOrder.products;
    }
  },
};
</script>