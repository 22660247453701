var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-3"},[(_vm.template)?_c('div',{},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendForm($event)}}},[_c('b-row',{staticClass:"align-items-end",attrs:{"cols":"1"}},[_c('transition-group',{attrs:{"name":"list","mode":"out-in","appear":""}},[_vm._l((_vm.template.template.domande),function(domanda,ind){return _c('b-row',{key:ind,staticClass:"mb-3"},[_c('b-col',{staticClass:"text-justify",attrs:{"md":"10","lg":"6","xl":"5","cols":"12"}},[_c('h6',{attrs:{"for":'q_' + ind}},[_c('span',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(ind + 1)+".")]),_vm._v(" "+_vm._s(domanda.q)+" "),(domanda.h)?_c('span',{staticClass:"mb-0 text-secondary"},[_vm._v(" - domanda per l'operatore")]):_vm._e()])]),_c('b-col',{staticClass:"text-justify",attrs:{"md":domanda.o === 'ricognizione' ? 12 : 8,"lg":domanda.o === 'ricognizione' ? 12 : 6,"xl":domanda.o === 'ricognizione' ? 12 : 7,"cols":"12"}},[(domanda.t)?[_c('b-form-group',{key:'fgr_' + ind},[(domanda.t === 'text')?[_c('b-form-input',_vm._b({staticClass:"align-self-center shadow_6",attrs:{"placeholder":"...","type":"text","name":'q_' + ind},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}},'b-form-input',
                        domanda.r && domanda.i !== false ? { required: true } : {}
                      ,false))]:(domanda.t === 'select')?[_c('b-form-select',{staticClass:"shadow_6 align-self-center",attrs:{"name":'q_' + ind,"required":""},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}},_vm._l((domanda.o),function(opt){return _c('b-form-select-option',{key:opt,attrs:{"value":opt}},[_vm._v(_vm._s(opt)+" ")])}),1)]:(domanda.t === 'select-multi')?[_c('b-form-tags',{staticClass:"mb-2",attrs:{"name":'q_' + ind,"size":"lg","add-on-change":"","no-outer-focus":""},on:{"focus":function($event){_vm.hasFocus = ind},"focusin":function($event){_vm.hasFocus = ind}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var tags = ref.tags;
                        var inputAttrs = ref.inputAttrs;
                        var inputHandlers = ref.inputHandlers;
                        var disabled = ref.disabled;
                        var removeTag = ref.removeTag;
                        var addTag = ref.addTag;
return [_c('b-form-select',_vm._g(_vm._b({staticClass:"shadow_6 align-self-center",attrs:{"disabled":disabled || domanda.o.length === 0,"options":domanda.o},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" scegli una o più opzioni... ")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers)),(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mt-2"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"secondary"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag))])],1)}),0):_vm._e()]}}],null,true),model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}}),(
                        Array.isArray(domanda.a) &&
                        domanda.a.includes('altro')
                      )?[(_vm.hasFocus === ind)?_c('b-input-group',[_c('b-form-input',{staticClass:"align-self-center input_with_append shadow_6",attrs:{"placeholder":"altra opzione personalizzata","type":"text","name":'qti_' + ind},model:{value:(_vm.tempTag),callback:function ($$v) {_vm.tempTag=$$v},expression:"tempTag"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){return _vm.addCustom(ind)}}},[_vm._v("aggiungi altro")])],1)],1):_vm._e()]:_vm._e()]:(domanda.t === 'date')?[_c('b-form-input',_vm._b({staticClass:"align-self-center shadow_6",attrs:{"placeholder":"GG/MM/AAAA","type":"date","name":'q_' + ind,"min":_vm.dateMin(domanda),"max":_vm.dateMax(domanda)},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}},'b-form-input',
                        domanda.r && domanda.i !== false ? { required: true } : {}
                      ,false))]:_vm._e()],2)]:(domanda.o === 'ricognizione')?[(domanda.o && domanda.o === 'ricognizione')?_c('b-row',{staticClass:"my-2 col-12",attrs:{"id":"verifica_terapie_col"}},[_c('b-row',{attrs:{"align-v":"stretch"}},[_c('b-col',{staticClass:"text-justify fw-bolder text-medium-grey bg-light general_border"},[_c('p',{staticClass:"my-2 py-2 fs-6"},[_vm._v(" Verifica ed aggiorna le terapie del paziente cliccando su \"Verifica terapia paziente\". Dopo aver aggiornato le terapie, completa ciascuna scheda con le informazioni mancanti. ")])]),_c('b-col',{staticClass:"align-self-center"},[_c('b-button',{on:{"click":function($event){return _vm.$bvModal.show('terapie_paziente')}}},[_vm._v("Verifica terapie paziente")])],1)],1),(_vm.schemaRicognizione)?_vm._l((_vm.schemaRicognizione),function(therapy,index){return _c('b-row',{key:therapy.hashid,staticClass:"px-2 mt-2"},[_c('h6',{staticClass:"text-left bg-prim-grad-1 text-white fw-bolder p-2 w-100"},[_c('span',{staticClass:"bg-secondary p-2"},[_vm._v("Terapia "+_vm._s(index + 1)+".")]),_vm._v(" "+_vm._s(therapy[0].a)+" ")]),_c('b-col',[_c('b-row',{attrs:{"cols":"1","cols-md":"2"}},_vm._l((therapy),function(field,position){return _c('b-col',[_c('b-form-group',{staticClass:"text-left"},[_c('label',[_vm._v(_vm._s(field.q)+":")]),(field.t === 'select')?[_c('b-form-select',_vm._b({staticClass:"shadow_6 align-self-center",attrs:{"name":'ric_' + index},model:{value:(
                                    _vm.schemaRicognizione[index][position].a
                                  ),callback:function ($$v) {_vm.$set(_vm.schemaRicognizione[index][position], "a", $$v)},expression:"\n                                    schemaRicognizione[index][position].a\n                                  "}},'b-form-select',
                                    field.r && field.i !== false
                                      ? { required: true }
                                      : {}
                                  ,false),_vm._l((field.o),function(opt){return _c('b-form-select-option',{key:opt,attrs:{"value":opt}},[_vm._v(_vm._s(opt)+" ")])}),1)]:(field.t === 'datetime')?[_c('b-form-input',_vm._b({staticClass:"shadow_6",attrs:{"type":"text","placeholder":"GG/MM/AAAA hh:mm","name":'ric_' + index,"readonly":field.i == false},model:{value:(
                                    _vm.schemaRicognizione[index][position].a
                                  ),callback:function ($$v) {_vm.$set(_vm.schemaRicognizione[index][position], "a", $$v)},expression:"\n                                    schemaRicognizione[index][position].a\n                                  "}},'b-form-input',
                                    field.r && field.i !== false
                                      ? { required: true }
                                      : {}
                                  ,false))]:_c('b-form-input',_vm._b({staticClass:"shadow_6",attrs:{"type":field.t,"readonly":field.i == false,"name":'ric_' + index},model:{value:(
                                  _vm.schemaRicognizione[index][position].a
                                ),callback:function ($$v) {_vm.$set(_vm.schemaRicognizione[index][position], "a", $$v)},expression:"\n                                  schemaRicognizione[index][position].a\n                                "}},'b-form-input',
                                  field.r && field.i !== false
                                    ? { required: true }
                                    : {}
                                ,false))],2)],1)}),1)],1)],1)}):_c('b-spinner')],2):_vm._e()]:[_c('b-form-group',{key:'fgr_' + ind},[(domanda.o && domanda.o === 'file')?_c('div',{staticClass:"align-self-center"},[(domanda.fm || !domanda.a || !domanda.a.length)?_c('b-button',{staticClass:"general_border",attrs:{"size":"sm"},on:{"click":function($event){return _vm.openUploadModal(domanda)}}},[_vm._v(" Aggiungi file "),_c('b-icon',{attrs:{"icon":"plus"}})],1):_vm._e(),_vm._l((domanda.a),function(element,fileIndex){return _c('b-row',{key:fileIndex,staticClass:"mt-2 d-flex align-items-center"},[_c('b-col',{staticClass:"mb-0 col-2"},[_vm._v("File "+_vm._s(fileIndex + 1))]),_c('b-col',{staticClass:"d-flex col-10 pl-0 justify-content-start text-left align-items-center"},[_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm","variant":"outline-primary general_border"},on:{"click":function($event){return _vm.downloadResource(element)}}},[_vm._v(" Scarica "),_c('b-icon',{attrs:{"icon":"download"}})],1),_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm","variant":"outline-danger general_border"},on:{"click":function($event){return _vm.deleteResource(element, domanda)}}},[_vm._v(" Elimina ")]),_c('span',{staticClass:"ml-3 point",on:{"click":function($event){return _vm.openShowModal(_vm.hist)}}},[_c('b-icon',{attrs:{"stacked":"","icon":"eye-fill","scale":"0.5","variant":"secondary"}})],1)],1)],1)})],2):(domanda.cb && domanda.o)?[_c('b-form-group',{attrs:{"label":"Seleziona almeno una opzione*","label-class":domanda.r ? 'fw-bolder underlined' : 'd-none'}},[_c('b-form-checkbox-group',_vm._b({attrs:{"options":domanda.o,"name":'checkboxquestions' + ind,"label":"Scegli almeno una opzione*","label-class":"fw-bolder"},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}},'b-form-checkbox-group',
                          domanda.r && domanda.a.length === 0
                            ? { required: true }
                            : {}
                        ,false))],1),(domanda.a.includes('altro'))?_c('b-form-input',{staticClass:"shadow_6 align-self-center border-bottom border-primary",attrs:{"placeholder":"altra motivazione...","type":"text","required":domanda.r},model:{value:(_vm.addendums[ind]),callback:function ($$v) {_vm.$set(_vm.addendums, ind, $$v)},expression:"addendums[ind]"}}):_vm._e()]:(domanda.o)?_c('b-form-select',_vm._b({staticClass:"align-self-center shadow_6",attrs:{"name":'q_' + ind},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}},'b-form-select',
                      domanda.r && domanda.i !== false
                        ? { required: true }
                        : {}
                    ,false),_vm._l((domanda.o),function(opt){return _c('b-form-select-option',{key:opt,attrs:{"value":opt}},[_vm._v(_vm._s(opt)+" ")])}),1):_c('b-form-input',_vm._b({staticClass:"align-self-center shadow_6",attrs:{"placeholder":"...","type":"text","name":'q_' + ind},model:{value:(domanda.a),callback:function ($$v) {_vm.$set(domanda, "a", $$v)},expression:"domanda.a"}},'b-form-input',
                      domanda.r && domanda.i !== false
                        ? { required: true }
                        : {}
                    ,false))],2)]],2),(
                domanda.o &&
                domanda.o === 'file' &&
                domanda.r &&
                (!domanda.a || !domanda.a.length)
              )?_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-danger text-left"},[_vm._v(" Questo campo è obbligatorio. ")])]):_vm._e()],1)}),_c('b-col',{key:"btsubmt",staticClass:"my-4 py-2"},[_c('b-button',{staticClass:"mx-1",attrs:{"type":"button","variant":"outline-danger fw-bolder"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Annulla")]),_c('b-button',{staticClass:"mx-1",attrs:{"type":"submit","variant":"primary primary_gradient fw-bolder","disabled":_vm.filesAreMissing}},[_vm._v(" Salva Modifiche")])],1)],2)],1)],1)],1):_vm._e(),_c('b-modal',{attrs:{"id":"delete-resource","title":"Elimina risorsa","button-size":"sm","cancel-variant":"secondary px-3 rounded-pill font-weight-bolder","ok-variant":"outline-danger px-3 font-weight-bolder rounded-pill","cancel-title":"Annulla","ok-title":"Elimina","footer-border-variant":"white","centered":""},on:{"ok":_vm.doDelete,"cancel":function($event){return _vm.resetDeleteFile()}}},[_c('h6',[_vm._v("Sei sicuro di voler eliminare la risorsa selezionata?")])]),_c('b-modal',{attrs:{"id":"terapie_paziente","hide-footer":"","centered":"","scrollable":"","size":"xl","title":"Aggiorna terapie paziente"},on:{"hide":_vm.fetchUserTherapies}},[_c('ArmadiettoComponent',{attrs:{"subject":{ type: 'user_in_app', hashid: _vm.patient.hashid },"ricognizione":true}})],1),_c('b-modal',{attrs:{"id":"upload-modal","title":"Carica file","size":"lg","hide-footer":"","centered":""}},[(_vm.uploadingToDomanda)?_c('SurveyUploader',{attrs:{"accepts":_vm.uploadingToDomanda.fa,"isMulti":_vm.uploadingToDomanda.fm},on:{"upload-result":function($event){return _vm.handleUpload($event)},"remove-result":function($event){return _vm.removeUpload(_vm.ind)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }